<template>
    <v-card>
        <v-card-text>
            <p class="text-center" v-if="sheets===null">
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </p>
            <sheetlist v-if="sheets!==null" :sheets="sheets" @reload="reload"></sheetlist>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios'
import sheetlist from './../components/sheetlist.vue';

export default {
    scrollToTop: false,
    name: 'sheets',
    components: {
        sheetlist
    },
    data: () => ({
        sheets: null,
        project:null
    }),
    created: function () {
        this.getSheetData();
    },
    computed:{
        output:function() {
            this.test.local().toISO();
        }
    },
    methods: {
        reload() {
            this.getSheetData()
        },
        createNewSheet: function() {
            this.$router.push({ name: 'time.createsheet' });
        },
        createSheetWithProject: async function(selectedProject) {
            let targetUrl = './wp-json/tm_time/v1/sheet';
            const requestData = this.$helper.transformSheetFormToSheet({
                id:null,
                start:new Date(),
                end:null,
                title:"neuer Zeitlog",
                notice:"",
                project:selectedProject
            });
            const { data } = await axios.post(targetUrl, requestData);
            if(data.id) {
                //request successfull
                this.getSheetData();
            }
        },
        getSheetData : async function() {
            let that = this;
            const { data } = await axios.get('./wp-json/tm_time/v1/sheetlist');
            this.sheets = this.$helper.transformSheets(data);
        },
    },
}
</script>