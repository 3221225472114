<template>
    <form @submit.prevent="submit" id="customerForm">
        <p>
            <v-text-field v-model="form.name" name="name" ref="firstInput" :placeholder="$t('time_customer_title')"></v-text-field>
        </p>
        <div class="alert alert-danger mb-3" role="alert" v-if="validation.name">
            {{$t('time_alert_choose_a_title')}}
            <button type="button" class="close" aria-label="Close" @click="validation.name=false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <p>
            <v-textarea id="customerFormNotice" v-model="form.notice" name="notice" :placeholder="$t('time_log_notice')"></v-textarea>
        </p>

        <div class="row">
            <div class="col-md-6">
                
            </div>
            <div class="col-md-6 text-right">
                <template v-if="form.id">
                    <v-btn type="button" class="secondary" @click="doDelete"><v-icon dark>mdi-delete</v-icon><!--{{$t('time_delete_customer')}}--></v-btn>
                    <v-btn class="primary" type="submit">{{$t('time_confirm')}}</v-btn>
                </template>
                <template v-else>
                    <v-btn class="primary" type="submit">{{$t('time_create_new_customer')}}</v-btn>
                </template>
            </div>
        </div>
    </form>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    components: { },
    props: [
        'data'
    ],
    data: () => ({
        form: {
            id:null,
            name:"",
            notice:""
        },
        validation:{
            name:false
        }
    }),
    methods: {
        loadCustomer:function() {
            if(this.data.id) this.form.id = parseInt(this.data.id);
            if(this.data.name) this.form.name = this.data.name;
            if(this.data.notice) this.form.notice = this.data.notice;
        },
        isFormValid:function() {
            var valid = true;
            this.validation.name = false;
            if(!this.form.name || this.form.name.lenght<1) {
                this.validation.name = true;
                valid = false;
            }
            return valid;
        },
        submit: async function() {
            let targetUrl = './wp-json/tm_time/v1/customer';
            let editMode = false;
            if(!this.isFormValid()) {
                return;
            }
            
            const requestData = {
                title: this.form.name,
                status: "publish",
                notice: this.form.notice
            };
            if(this.form.id!==null) {
                editMode = true;
                targetUrl+= '/'+this.form.id;
                requestData.id=this.form.id;
            }
            const { data } = await axios.post(targetUrl, requestData);
            if(data.id) {
                this.$router.push({ name: 'time.customer', params:{ id : data.id } });//redirect to entry
            }
            this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
        },
        doDelete : function() {
            let that = this;
            if(this.data.id && confirm( this.$t('time_customer_confirm_delete') )) {
                axios.delete('./wp-json/tm_time/v1/customer/'+that.$route.params.id, { 
                        params: {
                            id:that.$route.params.id, 
                            force: true 
                        }
                    })
                    .then(response => {
                        /*
                        // Redirect 
                        */
                        that.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
                        that.$router.push({ name: 'time.sheets' });
                    })
                    .catch(error => {
                        if(error.response.status===400) {
                            alert( that.$t('time_delete_customer__'+error.response.data.code) );
                        }
                    });
            }
        },
    },
    created() {
        this.loadCustomer();
        this.$nextTick(() => this.$refs.firstInput.focus());//prefocus on the first Input (defined with ref)
    },
    computed: {}
}
</script>