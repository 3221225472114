import axios from 'axios'
import Vue from 'vue'
import i18n from '~/modules/i18n'

/** mutationTypes ************************************************************************/
const mutationTypes = {
    FETCH_CUSTOMER: 'FETCH_CUSTOMER',
    FETCH_PROJECTS: 'FETCH_PROJECTS',
    FETCH_DEFAULT_TITLES: 'FETCH_DEFAULT_TITLES',
    FETCH_PROJECT_SHEETS: 'FETCH_PROJECT_SHEETS',//singular project-data to an array
    FETCH_LATEST_SHEETS: 'FETCH_LATEST_SHEETS',
};

const getDefaultState = () => {
    return {
        customers: null,
        projects: null,
        projectSheets: {},
        latestSheets: [],
        defaultSheetTitles: [],
    }
}

/** store module **************************************************************************/
export const storeModule = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        defaultSheetTitles: state => {
            console.log('store defaultSheetTitles',state.defaultSheetTitles, state);
            return state.defaultSheetTitles;
        },
        customers: state => state.customers,
        projects: state => state.projects,
        latestSheets: state => state.latestSheets,
        getProjectSheets: function(state) {
            return function(projectId) {
                return (state.projectSheets[projectId]) ? state.projectSheets[projectId] : null;
            }
        },
        getProjectById: (state) => (projectId) => {
            if(state.projects) {
                return state.projects.find(project => project.id == projectId);
            }
            return null;
        },
        getCustomerById: (state) => (customerId) => {
            if(state.customers) {
                return state.customers.find(customer => customer.id == customerId);
            }
            return null;
        }
    },
    mutations: {
        [mutationTypes.FETCH_CUSTOMER](state, { newValue }) {
            state.customers = newValue
        },
        [mutationTypes.FETCH_PROJECTS](state, { newValue }) {
            state.projects = newValue
        },
        [mutationTypes.FETCH_DEFAULT_TITLES](state, { newValue }) {
            state.defaultSheetTitles = newValue
        },
        [mutationTypes.FETCH_LATEST_SHEETS](state, { newValue }) {
            state.latestSheets = newValue
        },
        [mutationTypes.FETCH_PROJECT_SHEETS](state, { id, newValue }) {
            if(newValue!==null) {
                state.projectSheets[id] = newValue
            } else {
                if(state.projectSheets[id]) delete state.newValue[id];
            }
        },
        resetState(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        /**
         * fetch the baseData from the API (customers and projects),
         * <code>
         * // fetch with force reload
         * this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
         * </code>
         */
        async fetchBaseData({ commit,dispatch }, params) {
            dispatch('fetchLatestSheets');
            if(this.getters['time/customers']===null || this.getters['time/projects']===null || (params && params.force===true)) {
                //console.log('fetchBaseData loading needed');
                try {
                    const { data } = await axios.get('./wp-json/tm_time/v1/basedata', { params: { } });
                    commit(mutationTypes.FETCH_CUSTOMER, { newValue: Vue.prototype.$helper.transformCustomers(data.customers) });
                    commit(mutationTypes.FETCH_PROJECTS, { newValue: Vue.prototype.$helper.transformProjects(data.projects) });
                    commit(mutationTypes.FETCH_DEFAULT_TITLES, { newValue: data.defaultSheetTitles });
                } catch (e) {
                    commit(mutationTypes.FETCH_CUSTOMER, { newValue: null } );
                    commit(mutationTypes.FETCH_PROJECTS, { newValue: null } );
                    commit(mutationTypes.FETCH_DEFAULT_TITLES, { newValue: [] } );
                }
            }
        },
        async fetchProjectSheets({ commit }, params) {
            if(this.getters['time/getProjectSheets'](params.id)===null || params.force===true) {
                try {
                    const { data } = await axios.get('./wp-json/tm_time/v1/sheetlist', { params: { project : params.id } });
                    commit(mutationTypes.FETCH_PROJECT_SHEETS, { id: params.id, newValue: Vue.prototype.$helper.transformSheets(data) });
                } catch (e) {
                    commit(mutationTypes.FETCH_PROJECT_SHEETS_FAILURE, { id: params.id, newValue: null });
                }
            }
        },
        async fetchLatestSheets({commit}) {
            try {
                const { data } = await axios.get('./wp-json/tm_time/v1/sheetlist');
                commit(mutationTypes.FETCH_LATEST_SHEETS, { newValue: Vue.prototype.$helper.transformSheets(data) });
            } catch (e) {
                commit(mutationTypes.FETCH_LATEST_SHEETS, { newValue: null });
            }
        },
        resetState ({ commit }) {
            commit('resetState')
        },
    }
}
