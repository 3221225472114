<template>
    <v-card>
        <v-card-title>
            {{$t('time_create_new_sheet')}}
        </v-card-title>
        <v-card-text>
            <transition>
                <div class="alert alert-success" role="alert" v-if="loadedFromState">
                    {{$t('time_loaded_from_state')}}
                    <button type="button" class="close" aria-label="Close" @click="loadedFromState=false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </transition>
            <sheetform :data="sheet"></sheetform>
        </v-card-text>
    </v-card>
</template>

<script>
import sheetform from './../components/sheetForm.vue';

export default {
    name: 'sheet',
    components: {
        sheetform
    },
    data: () => ({
        sheet:{
            start: new Date(),
            end: new Date(),
            title: "",
            notice: "",
            project: null
        },
        loadedFromState:false
    }),
    created: function () {
        if(this.$store.state.time.sheet!==null) {
            this.sheet.start   = this.$store.state.time.sheet.start;
            this.sheet.end     = this.$store.state.time.sheet.end;
            this.sheet.project = this.$store.state.time.sheet.project;
            this.loadedFromState = true;
        }
    },
    computed:{
    },
    methods: {
    },
}
</script>