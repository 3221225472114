import Vue from 'vue'
import Vuetify from 'vuetify/dist/vuetify.min.js'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify)

export default new Vuetify({
    //icons: { iconfont: 'mdi', /* 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' */},
    theme: {
        themes: {
            light: {
                //secondary: '#aaa',
            }
        }
    }
});
