<template>
	<div class="projectList">
		<p>
			<v-btn small color="primary" class="d-print-none copyToClipboard" @click="copyToClipboard( createExcelVersion() )">{{$t('time_customer_analysis_to_cache')}}</v-btn>
		</p>

		<v-data-table
			class="table table-striped table-sm"
			:headers="headers"
			:items="projectList"
			:footer-props="{ itemsPerPageOptions: [ {text: 'alle',value:-1} ]  }"
			hide-default-footer
		>
			<template v-slot:body="{ items }">
				<tbody>
					<tr v-for="(item,$index) in items" :key="$index">
						<td class="text-xs-left">{{ $helper.decode(item.name) }}</td>
						<td class="text-xs-right">{{ getDurationString( item.currentSum ) }}</td>
						<td class="text-xs-right">{{ getDezimalHourString( item.currentSum ,2) }}</td>
						<td class="text-xs-right">{{ getDezimalPersonDaysString( item.currentSum ,2) }}</td>
					</tr>
				</tbody>
			</template>
		</v-data-table>

		<div class="chartWrap" v-if="chartData">
			<Chart :config="chartConfig" :data="chartData"></Chart>
		</div>
	</div>
</template>

<script>
import Chart from './../billboardChart.vue';
export default {
	components: { Chart },
	props: {
		projects:{ required:true, type: Object }
	},
	data: () => ({
		headers: [
			{ text: 'Projekt', value: 'name', align: 'left', },
			{ text: 'Summe [h:m]', value: 'currentSum', align: 'right', },
			{ text: 'Summe dezimal', value: 'currentSum', align: 'right', },
			{ text: 'Summe Personentage', value: 'currentSum', align: 'right', },
		],
		chartConfig:{
			donut: {
				title: 'Projekte'
			}
		},
		chartData:null,
	}),
	mounted() {
		this.prepareChartData();
	},
	computed: {
		projectList() {
			// order by name and return
			return Object.values(this.projects).sort(function(a, b){
				if(a.name < b.name) { return -1; }
				if(a.name > b.name) { return 1; }
				return 0;
			});
		}
	},
	methods: {
		prepareChartData() {
			let result = [];
			this.projectList.forEach(elem => {
				result.push( [elem.name, elem.currentSum] );
			});
			this.chartData = {
				type: 'donut',
				columns: result
			};
		},
		getDurationString(diff) {
			var hours = Math.floor(diff / 60 / 60);
			var minutes = Math.floor(diff / 60);
			var result = '';
			result+= hours + ':';
			minutes = minutes - (hours * 60);
			result+= new String(minutes).padStart(2,'0');
			return result;
		},
		createExcelVersion() {
			let result = "Projekt\tDauer\tDauer (dezimal)\tDauer (Personentage)\n";
			let that = this;
			this.projectList.forEach(function(obj) {
				result+= obj.name+"\t\""+that.getDurationString(obj.currentSum)+"\"\t\""+ that.getDezimalHourString(obj.currentSum,2) +"\"\t\""+ that.getDezimalPersonDaysString(obj.currentSum,2)+"\"\n";
			});
			return result;
		},
		getDezimalHourString(seconds,fixed){
			if(typeof fixed !== 'number') fixed = 3;
			return (seconds / 60 / 60).toFixed(fixed).replace(",","").replace(".",",");
		},
		getDezimalPersonDaysString(seconds,fixed){
			if(typeof fixed !== 'number') fixed = 3;
			return (seconds / 60 / 60 / 8).toFixed(fixed).replace(",","").replace(".",",");
		},
		copyToClipboard(text) {
			const el = document.createElement('textarea');
			el.value = text;
			el.setAttribute('readonly', '');
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			alert('Daten dieser Ansicht wurden als CSV in die Zwischenablage gelegt.')
		}
	}
}

</script>