export default {
    "ok": "Ok",
    "cancel": "Cancel",
    "reset": "reset",
    "sw_reload_text": "New APP version is available, click OK to update.",
    "loading": "loading",
    "error_alert_title": "Oops...",
    "error_alert_text": "Something went wrong! Please try again.",
    "token_expired_alert_title": "Session Expired!",
    "token_expired_alert_text": "Please log in again to continue.",
    "login": "Log In",
    "register": "Register",
    "no_access_rights": "access restricted",
    "page_not_found": "Page Not Found",
    "go_home": "Go Home",
    "logout": "Logout",
    "username": "Username",
    "email": "Email",
    "remember_me": "Keep logged in",
    "password": "Password",
    "forgot_password": "Forgot Your Password?",
    "confirm_password": "Confirm Password",
    "name": "Name",
    "toggle_navigation": "Toggle navigation",
    "home": "Home",
    "you_are_logged_in": "You are logged in!",
    "reset_password": "Reset Password",
    "send_password_reset_link": "Send Password Reset Link",
    "settings": "Settings",
    "profile": "Profile",
    "your_info": "Your Info",
    "info_updated": "Your info has been updated!",
    "update": "Update",
    "your_password": "Your Password",
    "password_updated": "Your password has been updated!",
    "new_password": "New Password",
    "login_with": "Login with",
    "register_with": "Register with",
    
    //module userroles
    "userroles": "Userroles",
    "role": "role",


    //timesheetmanager
    "time_minimize": "minimize",
    "time_navigation_all": "all",
    "time_start": "Start",
    "time_stop": "Stop",
    "time_back": "zurück",
    "time_sum": "sum",
    "time_edit": "edit",
    "time_currently_running": "currently running",
    "time_customer_tab_last": "last",
    "time_customer_tab_by_name": "by name",
    "time_customer_tab_by_creation": "by creation",
    "time_day_of_the_week-0": "Sunday",
    "time_day_of_the_week-1": "Monday",
    "time_day_of_the_week-2": "Tuesday",
    "time_day_of_the_week-3": "Wednesday",
    "time_day_of_the_week-4": "Thursday",
    "time_day_of_the_week-5": "Friday",
    "time_day_of_the_week-6": "Saturday",
    "time_please_choose": "please choose",
    "time_customers": "customers",
    "time_customer": "customer",
    "time_sheets": "time logs",
    "time_sheet": "time log",
    "time_projects": "projects",
    "time_project": "project",
    "time_search_for_project":"search",
    "time_search_for_project_no_match": "no matching entry found",
    "overview": "overview",
    "time_create_new_sheet": "create log",
    "time_start_sheet": "start log",
    "time_new_timelog_default_title":"new time-log",
    "time_log_title": "title",
    "time_log_title_menu_label":"use title",
    "time_log_title_menu_no_sheets": "There are no time logs available yet.",
    "time_log_notice": "notice",
    "time_log_break": "breaktime (in minutes)",
    "time_delete_sheet": "delete log",
    "time_move_sheet":"move",
    "time_sheet_confirm_delete": "Are you sure you want to delete this time log?",
    "time_loaded_from_state": "The following data can now be saved.",
    "time_edit_sheet": "edit log",
    "time_confirm":"confirm",
    "time_choose":"choose",
    "time_abbr_days":"D",
    "time_abbr_hours":"h",
    "time_abbr_minutes":"min",
    "time_label_start_date": "Start date",
    "time_label_end_date": "End date",
    "time_label_start_time": "Start time",
    "time_label_end_time": "End time",
    "time_date_settings_today":"Use today`s date",
    "time_date_settings_date_from_end":"Use date from END",
    "time_date_settings_date_from_start":"Use date from START",
    "time_time_settings_now":"Set time to \"now\"",
    "time_time_settings_time_from_start":"Use time from START",
    "time_time_settings_time_from_end":"Use time from END",
    "time_end_date_before_start_date":"The end time is before the start time!",
    "time_alert_choose_a_project":"Please choose a project.",
    "time_add_project":"add project",
    "time_project_title":"projekt name",
    "time_choose_a_customer":"Choose a customer",
    "time_alert_choose_a_customer":"Please choose a customer.",
    "time_alert_choose_a_title":"Please insert a project name.",
    "time_create_new_project":"create project",
    "time_edit_project":"edit",
    "time_delete_project":"delete",
    "time_project_confirm_delete":"Are you sure you want to delete this project?",
    "time_notice":"notice",
    "time_customer_edit_title":"edit costomer",
    "time_create_new_customer":"create new customer",
    "time_customer_title":"customer name",
    "time_add_customer":"create customer",
    "time_edit_customer":"edit",
    "time_delete_customer__rest_customer_not_empty": "The customer still has projects. You have to delete all projects before you can delete the customer",
    "time_delete_project__rest_project_not_empty": "This project still has time logs. You have to delete all time logs before you can delete the project",
    "time_delete_customer":"delete",
    "time_customer_confirm_delete":"Are you sure you want to delete this customer?",
    "time_reload_sheetlist":"reload list",
    "time_select_project_headline": "Select assignment",
    "time_select_customer": "Select customers",
    "time_select_project": "Select project",
    "time_default_sheet_title_1":"Consulting",
    "time_default_sheet_title_2":"Bugfix",
    "time_default_sheet_title_3":"Design",
    "time_default_sheet_title_4":"Meeting",
    "time_default_sheet_title_5":"Programming",

    "time_multi_edit_selected_entries": "Selected entries:",
    "time_multi_edit_delete_dialog_headline": "Delete entries",
    "time_multi_edit_confirm_delete": "Are you sure you want to delete these projects?",
    "time_multi_edit_move_to_project": "Move to project",
    "time_multi_edit_choose_entrie_first": "Select entry first",

    "time_customer_analysis":"customer analysis",
    "time_customer_analysis_duration":"duration",
    "time_customer_analysis_duration_time":"[h:m]",
    "time_customer_analysis_duration_dezimal":"decimal",
    "time_customer_analysis_duration_persondays":"Person days",
    "time_customer_analysis_to_cache":"copy to cache",
    "time_customer_analysis_reset_filtering":"reset filtering",
    "time_customer_analysis_back_to_customer":"back to customer",
    "time_customer_analysis_from":"from",
    "time_customer_analysis_to":"till",
    "time_customer_analysis_header_start_date":"Start-date",
    "time_customer_analysis_header_end_date":"End-date",
    "time_customer_analysis_header_timelisting":"Timelisting",
    "time_customer_analysis_header_projectlisting":"Projectlisting",
    "time_customer_analysis_show_all":"show all",

    "time_project_analysis":"project analyse",
    "time_project_analysis_back_to_project": "back to the project",
    "time_project_analysis_from": "from",
    "time_project_analysis_to": "till",
    "time_project_analysis_header_start_date": "Start date",
    "time_project_analysis_header_end_date": "End date",
    "time_project_analysis_header_timelisting": "Timelisting",
    "time_project_analysis_show_all": "show all",

    "time_analysis_warning_many_entries":"This request contains {0} entries. Do you really want it to display every entry?",
    "time_analysis_warning_many_entries_btn":"show",

    "usersettings_headline": "Settings",
    "aria_close": "Close",
    "usersettings_user_subheader": "User settings",
    "usersettings_lang_title": "Language",
    "usersettings_lang_desc": "Select the desired language",
};