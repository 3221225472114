
<template>
    <section class="sheetlist">
        <v-btn v-if="!hideReload" block class="reloadButton my-2 blue lighten-5 blue--text text--lighten-1" small depressed @click="$emit('reload',true)">{{$t('time_reload_sheetlist')}}</v-btn>
        <div v-if="sheets">
            <ul class="list-unstyled" v-for="(day,key) in dayGroup" :key="key">
                <li class="newDay">
                    <dateformat :date="day.date" format="EEE dd.MM.yyyy" class="from"></dateformat>
                    <span class="duration pl-3 d-inline-block">
                        {{getDiff(day.duration)}}
                    </span>
                </li>
                <li v-for="sheet in day.sheets" :key="sheet.id">
                    <sheetrow :sheetEntry="sheet" @change="updateSheet" :selectable="multiEdit"/>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import sheetrow from './sheetrow.vue';
import dateformat from '~/components/dateformat.vue';

export default {
    components: {
        sheetrow,
        dateformat
    },
    props:{
        sheets : {
            required:true,
            type:Array,
            default:null
        },
        hideReload : {
            required:false,
            type:Boolean,
            default:false
        },
        multiEdit : {
            required:false,
            type:Boolean,
            default:false
        }
    },
    data : () => ({
        multiSelect : [],
    }),
    
    computed:{
        sheetSum() {
            if(this.sheets===null) return null;
            let sum = 0;
            this.sheets.forEach((elem) => {
                var end = elem.end;
                if(end===null) end = (new Date());
                sum+= (end - elem.start) / 1000;
                sum = sum - elem.break * 60;
            });
            return this.$helper.getHumanReadableByDiffSeconds(sum);
        },
        dayGroup() {
            let that = this;
            if(this.sheets===null) return null;
            let days = {};

            this.sheets.forEach(elem => {
                //get key for grouping
                var currentDay = new Date(elem.start.getFullYear(), elem.start.getMonth(), elem.start.getDate());
                var key = currentDay.getTime();
                var day = {
                    date: currentDay,
                    sheets : [],
                    duration : 0
                };

                //check if day exists
                if(typeof days[key] !== 'undefined') {
                    day = days[key];
                } else {
                    days[key] = day;
                }

                //add sheet to day
                day.sheets.push(elem);

                //get duration of item
                var end = elem.end;
                if(end===null) end = (new Date());
                elem.duration = (end - elem.start) / 1000;
                elem.duration = elem.duration - elem.break * 60;
                day.duration+= elem.duration;
            });
            return days;
        }
    },
    mounted() {
        document.addEventListener("visibilitychange", this.handleVisibility);
    },
    beforeDestroy() {
        document.removeEventListener("visibilitychange", this.handleVisibility);
    },
    methods: {
        handleVisibility() {
            if (document.visibilityState === 'visible') {
                //console.log('change to visible');
                this.$emit('reload',true)
            } else {
                //console.log('change to not visible');
            }
        },
        getDiff(diff) {
            return this.$helper.getHumanReadableByDiffSeconds(diff);
        },
        updateSheet(item) {
            this.$emit('change', this.sheets);
        }
    },
}
</script>