<template>
    <span class="currentlyRunning">
        <span class="mr-3"><v-icon>mdi-update</v-icon> {{$t('time_currently_running')}} (<timedifference :start="sheet.start" :end="now" :break="sheet.break"></timedifference>)</span>
    </span>
</template>
<script>
import timedifference from './../components/timedifference.vue';
export default {
    components: {
        timedifference
    },
    props: {
        sheet: {
            required:true
        }
    },
    data: () => ({
        interval : undefined,
        now : Date.now()
    }),
    created:function() {
        this.addDateUpateInterval();
    },
    beforeDestroy:function() {
        this.removeDateUpateInterval();
    },
    methods: {
        /**
         * Adds an interval to update the now-value
         */
        addDateUpateInterval() {
            let that = this;
            this.interval = setInterval(function() { 
                that.now = Date.now(); 
                if(that.sheet && that.sheet.end) {
                    that.removeDateUpateInterval();
                }
            }, 5000);
        },
        /**
         * remove the update interval
         */
        removeDateUpateInterval() {
            if(this.interval) {
                clearInterval(this.interval);
                this.interval = undefined;
            }
        },
    }
}
</script>

