<template>
    <span v-if="pastTime" class="duration">
        {{pastTime}}
    </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {},
    props: {
        start : {
            required: true
        },
        end : {
            default:undefined,
            required: false
        },
        break : {
            default:0,
            required: false,
            validator: function (value) {
                return value>=0;
            }
        }
    },
    data: () => ({
    }),
    methods: {
    },
    computed: {
        ...mapGetters({
            projects: 'time/projects'
        }),
        pastTime: function() {
            if(!this.end) return '';
            var diff = (this.end - this.start) / 1000;
            diff = diff - this.break * 60;
            return this.$helper.getHumanReadableByDiffSeconds(diff);
            
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
