import './modules/registerServiceWorker.js';

import Vue from 'vue'
import App from './App.vue';
import './style/index.scss';
import './components/global';

import router from './router/index'
import store from './store'
import i18n from './modules/i18n'
import './modules/axios.interceptors';
import './modules/helper';

import vuetify from './modules/vuetify.js';

Vue.config.productionTip = false

new Vue({
    vuetify,
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App)
});