<template>
    <article v-if="sheetEntry" class="sheetRow" :class="{ 'active' : isActive }">
        <v-checkbox class="sheetSelect" v-if="selectable && !isActive" v-model="sheetEntry.selected" @change="selectedChanged"></v-checkbox>
        <div class="sheetInfo text-truncate">
            <div class="sheetTime">
                <span v-if="!sheetEntry.end">{{ $t('time_label_start_time') }}:</span>
                <dateformat v-if="sheetEntry.start" :date="sheetEntry.start" format="HH:mm" class="from"></dateformat>
                <span class="labelTill" v-if="sheetEntry.end">{{ $t('time_label_till') }}</span>
                <dateformat v-if="sheetEntry.end" :date="sheetEntry.end" format="HH:mm" class="till"></dateformat>
                <span class="timedifference pl-3" v-if="sheetEntry.end">
                    <i aria-hidden="true" class="v-icon mdi mdi-update mdiSizeSmall theme--light"></i>
                    <timedifference :start="sheetEntry.start" :end="sheetEntry.end" :break="sheetEntry.break"></timedifference>
                </span>
            </div>
            <h2 @click="clickView()" class="text-truncate">{{ sheetEntry.title }}</h2>
            
            <div v-if="isActive" class="currentlyRunning">
                <currently-running :sheet="sheetEntry"></currently-running>
                <v-btn class="yellow darken-2" depressed dark small @click="clickEdit(true)">
                    <i aria-hidden="true" class="v-icon mdi mdi-flag theme--light"></i>
                    {{$t('time_stop')}}
                </v-btn>
            </div>

            <p class="quickinfo">
                
                <span v-if="project" class="customer pr-2">
                    <router-link :to="{ name: 'time.customer', params: { id: project.customer.id }}">
                        <i aria-hidden="true" class="v-icon mdi mdi-account mdiSizeSmall theme--light"></i>
                        <span>{{project.customer.name}}</span>
                    </router-link>
                </span>
                <span v-if="project" class="project">
                    <router-link :to="{ name: 'time.project', params: { id: project.id }}">
                        <i aria-hidden="true" class="v-icon mdi mdi-layers mdiSizeSmall theme--light"></i>
                        <span>{{project.name}}</span>
                    </router-link>
                </span>
            </p>
            
        </div>
        <div class="sheetEdit">
            <v-btn type="button" icon text @click="clickEdit()" data-cy="edit">
                <i aria-hidden="true" class="v-icon mdi mdi-pencil theme--light"></i>
            </v-btn>
        </div>
    </article>
</template>

<script>
import { mapGetters } from 'vuex';
import dateformat from '~/components/dateformat.vue';
import timedifference from './../components/timedifference.vue';
import currentlyRunning from './../components/currentlyRunning.vue';

export default {
    components: {
        dateformat,
        timedifference,
        currentlyRunning,
    },
    props: {
        sheetEntry: {
            required:true,
            type:Object,
            default:null
        },
        selectable : {
            required:false,
            type:Boolean,
            default:false
        }
    },
    methods: {
        /**
         * redirect to sheet
         */
        clickView() {
            //this.$router.push({ name: 'time.sheet', params: { id: this.sheetEntry.id }});
            this.clickEdit(false);
        },
        clickEdit: function(shouldStopAndEdit) {
            if(this.sheetEntry.end===null && shouldStopAndEdit===true) {
                this.$router.push({ name: 'time.editsheet', params: { id: this.sheetEntry.id, stopSheet:true }});
            } else {
                this.$router.push({ name: 'time.editsheet', params: { id: this.sheetEntry.id }});
            }
        },
        emptyClick() {/* needed to prevent click when "stop" is active*/},
        selectedChanged() {
            this.$emit('change', this.sheetEntry);
        },
    },
    computed: {
        ...mapGetters({
            projects: 'time/projects'
        }),
        project: function() {
            return this.$helper.getProject(this.sheetEntry.projectId, this.projects);
        },
        isActive() {
            return !this.sheetEntry.end;
        }
    }
}
</script>