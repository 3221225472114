import axios from 'axios'
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        multiEdit: {
            show:false,
            items:[],
            deleteConfirmDialog:false,
        }
    }),
    computed:{
        ...mapGetters({
            projects: 'time/projects'
        }),
        selectedSheets() {
            if(this.sheets===null) return [];
            return this.sheets.filter(value => value.selected===true);
        },
        projectForMoveSheets() {
            if(this.selectedSheets.length === 0) return null;
            return this.$helper.getProject(this.selectedSheets[0].projectId, this.projects);
        }
    },
    methods:{
        multiSelectAll() {
            if(this.sheets) {
                for (let index = 0; index < this.sheets.length; index++) {
                    if(this.sheets[index].end) {//check if sheets has an end value
                        this.sheets[index].selected = true;
                        this.sheets.splice(index, 1, this.sheets[index]);//needed to update the value
                    }
                }
            }
        },
        multiSelectNone() {
            if(this.sheets) {
                for (let index = 0; index < this.sheets.length; index++) {
                    if(this.sheets[index].end) {//check if sheets has an end value
                        this.sheets[index].selected = false;
                        this.sheets.splice(index, 1, this.sheets[index]);//needed to update the value
                    }
                }
            }
        },
        multiMoveToProject(resultProject) {
            let that = this;
            if(resultProject.id!==this.selectedSheets[0].projectId) {
                let targetUrl = './wp-json/tm_time/v1/multi-sheet-move-to-project';
                let requestData = {
                    sheets : [],
                    project: resultProject.id
                };
                this.selectedSheets.forEach(elem => {
                    requestData.sheets.push(elem.id);
                });
                axios.post(targetUrl, requestData)
                    .then(response => {
                        that.reload();
                    })
                    .catch(error => {
                        console.log('axios catch',error);
                        if(error.response.status===400) {
                            alert( 'Fehler beim Verschieben in anderes Projekt' );
                        }
                        if(error.response.status===500) {
                            alert( error.response.message );
                        }
                    });
            }
        },
        multiConfirmDelete(result) {
            let that = this;
            this.multiEdit.deleteConfirmDialog = false;
            if(result===true) {
                let targetUrl = './wp-json/tm_time/v1/multi-sheet-delete';
                let requestData = {
                    sheets : []
                };
                this.selectedSheets.forEach(elem => {
                    requestData.sheets.push(elem.id);
                });
                axios.post(targetUrl, requestData)
                    .then(response => {
                        that.reload();
                    })
                    .catch(error => {
                        console.log('axios catch',error);
                        if(error.response.status===400) {
                            alert( 'Fehler beim multiselect löschen' );
                        }
                        if(error.response.status===500) {
                            alert( error.response.message );
                        }
                    });
            }
        }
    }
}