import store from '../store'

export default [
    { 
        path: '/', 
        name:'home',
        redirect: to => {
            if (store.state.auth.user && store.state.auth.user.roles[0] === 'administrator') return 'time';
            return 'welcome';
        }
    }, {
        path: '/welcome',
        name: 'welcome',
        component: require('~/pages/welcome.vue').default,
    }, 
    
    /********** AUTH ROUTES ************/
    {
        path: '/login', 
        name: 'login', 
        component: require('~/pages/login.vue').default, 
        meta: { bodyClass: 'login narrowContent' },
    }, {
        path: '/register',
        name: 'register',
        component: require('~/modules/auth/pages/register.vue').default, 
        meta: { bodyClass: 'register narrowContent' },
    }, {
        path: '/settings',
        component: require('~/modules/auth/pages/settings/index.vue').default,
        meta: { bodyClass: 'settings' },
        children: [
            { path: '', redirect: { name: 'settings.profile' } },
            { path: 'profile', name: 'settings.profile', component: require('~/modules/auth/pages/settings/profile.vue').default },
            { path: 'password', name: 'settings.password', component: require('~/modules/auth/pages/settings/password.vue').default }
        ]
    }, {
        path: '/password/reset', 
        name: 'password.request', 
        component: require('~/modules/auth/pages/email.vue').default,
        meta: { bodyClass: 'passwordReset narrowContent' },
    }, {
        path: '/password/reset/:token', 
        name: 'password.reset', 
        component: require('~/modules/auth/pages/resetpassword.vue').default,
        meta: { bodyClass: 'passwordReset narrowContent' },
    },
    /********** END AUTH ROUTES ************/

    /********** USER ROLES *****************/
    {
        path: '/userroles/',
        name: 'userroles',
        component: require('~/modules/userroles/pages/list.vue').default,
        meta: { bodyClass: 'userroles' },
    },
    /********** END USER ROLES *****************/
    {
        path: '/time',
        component: require('~/pages/index.vue').default,
        meta: { bodyClass: 'time' },
        children: [
            { path: '', name: 'time', redirect: { name: 'time.dashboard' } },
            { path: 'dashboard', name: 'time.dashboard', component: require('~/pages/dashboard.vue').default, meta: { bodyClass: 'dashboard' } },
            { path: 'sheet', name: 'time.sheets', component: require('~/pages/sheets.vue').default, },
            { path: 'sheet/:id', name: 'time.sheet', component: require('~/pages/sheet.vue').default, },
            { path: 'createsheet', name: 'time.createsheet', component: require('~/pages/sheet_create.vue').default, },
            { path: 'editsheet/:id', name: 'time.editsheet', component: require('~/pages/sheet_edit.vue').default, props: true },
            { path: 'project/:id', name: 'time.project', component: require('~/pages/project.vue').default, },
            { path: 'createproject', name: 'time.createproject', component: require('~/pages/project_create.vue').default, props: true },
            { path: 'editproject/:id', name: 'time.editproject', component: require('~/pages/project_edit.vue').default, },
            { path: 'customer/:id', name: 'time.customer', component: require('~/pages/customer.vue').default, },
            { path: 'createcustomer', name: 'time.createcustomer', component: require('~/pages/customer_create.vue').default, },
            { path: 'editcustomer/:id', name: 'time.editcustomer', component: require('~/pages/customer_edit.vue').default, },
        ]
    },
    { path: '/time/customeranalyse/:id', name: 'time.customeranalysis', component: require('~/pages/customer_analysis.vue').default, },
    { path: '/time/projectanalyse/:id', name: 'time.projectanalysis', component: require('~/pages/project_analysis.vue').default, },
    { path: '*', component: require('~/pages/404.vue').default, meta: { bodyClass: 'page404' }, }
];