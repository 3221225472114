export default {
    "ok": "Ok",
    "cancel": "Abbrechen",
    "reset": "zurücksetzen",
    "sw_reload_text": "Neue APP-Version ist verfügbar, OK klicken um zu Aktualisieren.",
    "loading": "lade",
    "error_alert_title": "Oops...",
    "error_alert_text": "Da ist etwas schiefgegangen bitte versuchen Sie es erneut.",
    "token_expired_alert_title": "Sitzung abgelaufen!",
    "token_expired_alert_text": "Bitte melden Sie sich erneut an um fortzufahren.",
    "login": "Login",
    "register": "Registrieren",
    "no_access_rights": "Zugriff verweigert",
    "page_not_found": "Seite nicht gefunden",
    "go_home": "Zurück zum Start",
    "logout": "Logout",
    "username": "Benutzername",
    "email": "E-Mail",
    "remember_me": "Eingeloggt bleiben",
    "password": "Passwort",
    "forgot_password": "Haben Sie ihr Passwort vergessen?",
    "confirm_password": "Passwort bestätigen",
    "name": "Name",
    "toggle_navigation": "Menü ausklappen",
    "home": "Start",
    "you_are_logged_in": "Anmeldung erfolgreich!",
    "reset_password": "Passwort zurücksetzen",
    "send_password_reset_link": "Sende Link zum Zurücksetzen des Passworts",
    "settings": "Einstellungen",
    "profile": "Profil",
    "your_info": "Ihre Daten",
    "info_updated": "Ihre Daten wurden aktualisiert!",
    "update": "Update",
    "your_password": "Ihr Passwort",
    "password_updated": "Ihr Passwort wurde aktualisiert!",
    "new_password": "Neues Passwort",
    "login_with": "Einloggen als",
    "register_with": "Registieren als",

    //module userroles
    "userroles": "Benutzer",
    "role": "Rolle",


    //timesheetmanager
    "time_minimize": "minimieren",
    "time_navigation_all": "alle",
    "time_start": "Start",
    "time_stop": "Stop",
    "time_back": "zurück",
    "time_sum": "Summe",
    "time_edit": "bearbeiten",
    "time_currently_running": "läuft gerade",
    "time_customer_tab_last": "zuletzt",
    "time_customer_tab_by_name": "nach Name",
    "time_customer_tab_by_creation": "nach Erstellung",
    "time_day_of_the_week-0": "Sonntag",
    "time_day_of_the_week-1": "Montag",
    "time_day_of_the_week-2": "Dienstag",
    "time_day_of_the_week-3": "Mittwoch",
    "time_day_of_the_week-4": "Donnerstag",
    "time_day_of_the_week-5": "Freitag",
    "time_day_of_the_week-6": "Samstag",
    "time_please_choose": "bitte auswählen",
    "time_customers": "Kunden",
    "time_customer": "Kunde",
    "time_sheets": "Zeitlogs",
    "time_sheet": "Zeitlog",
    "time_projects": "Projekte",
    "time_project": "Projekt",
    "time_search_for_project":"suchen",
    "time_search_for_project_no_match": "kein passender Eintrag gefunden",
    "overview": "Navigation",
    "time_create_new_sheet": "Log erstellen",
    "time_start_sheet": "Log starten",
    "time_new_timelog_default_title":"Neuer Zeitlog",
    "time_log_title": "Titel",
    "time_log_title_menu_label":"Titel übernehmen",
    "time_log_title_menu_no_sheets": "Bisher sind keine Zeitlogs vorhanden.",
    "time_log_notice": "Notiz",
    "time_log_break": "Pausenzeit (in Minuten)",
    "time_delete_sheet":"Log löschen",
    "time_move_sheet":"verschieben",
    "time_sheet_confirm_delete": "Wollen Sie diesen Zeitlog wirklich löschen?",
    "time_loaded_from_state": "Folgende Daten können nun gespeichert werden",
    "time_edit_sheet":"bearbeiten",
    "time_confirm":"übernehmen",
    "time_choose":"auswählen",
    "time_abbr_days":"T",
    "time_abbr_hours":"h",
    "time_abbr_minutes":"min",
    "time_label_start_date":"Start-Datum",
    "time_label_end_date":"End-Datum",
    "time_label_start_time":"Start-Zeit",
    "time_label_end_time":"End-Zeit",
    "time_label_till":"bis",
    "time_date_settings_today":"Heutiges Datum setzen",
    "time_date_settings_date_from_end":"Datum von ENDE übernehmen",
    "time_date_settings_date_from_start":"Datum von START übernehmen",
    "time_time_settings_now":"Setze Zeit auf \"jetzt\"",
    "time_time_settings_time_from_start":"Zeit von START übernehmen",
    "time_time_settings_time_from_end":"Zeit von ENDE übernehmen",
    "time_end_date_before_start_date":"Der End-Zeitpunkt befindet sich vor dem Start-Zeitpunkt!",
    "time_alert_choose_a_project":"Bitte wählen Sie ein Projekt aus.",
    "time_add_project":"Projekt hinzufügen",
    "time_project_title":"Projekt Name",
    "time_choose_a_customer":"Wählen Sie einen Kunden aus",
    "time_alert_choose_a_customer":"Bitte wählen Sie einen Kunden aus",
    "time_alert_choose_a_title":"Bitte geben Sie einen Projektnamen an.",
    "time_create_new_project":"Projekt erstellen",
    "time_edit_project":"bearbeiten",
    "time_delete_project":"löschen",
    "time_project_confirm_delete":"Wollen Sie dieses Projekt wirklich löschen?",
    "time_notice":"Notiz",
    "time_customer_edit_title":"Kunden bearbeiten",
    "time_create_new_customer":"Neuen Kunden erstellen",
    "time_customer_title":"Name des Kunden",
    "time_add_customer":"Kunden erstellen",
    "time_edit_customer":"bearbeiten",
    "time_delete_customer__rest_customer_not_empty":"Der Kunde hat noch Projekte. Sie müssen erst alle Projekte löschen, damit Sie den Kunden löschen können.",
    "time_delete_project__rest_project_not_empty":"Dieses Project hat noch Zeitlogs. Sie müssen erst alle Zeitlogs löschen, damit Sie das Projekt löschen können.",
    "time_delete_customer":"löschen",
    "time_customer_confirm_delete":"Wollen Sie diesen Kunden wirklich löschen?",
    "time_reload_sheetlist":"Liste neu laden",
    "time_select_project_headline":"Zuordnung auswählen",
    "time_select_customer":"Kunden auswählen",
    "time_select_project":"Projekt auswählen",
    "time_default_sheet_title_1":"Beratung",
    "time_default_sheet_title_2":"Bugfix",
    "time_default_sheet_title_3":"Design",
    "time_default_sheet_title_4":"Meeting",
    "time_default_sheet_title_5":"Programmierung",

    "time_multi_edit_selected_entries": "Selektierte Einträge:",
    "time_multi_edit_delete_dialog_headline": "Einträge löschen",
    "time_multi_edit_confirm_delete": "Sind sie sicher das sie diese Projekte löschen möchten?",
    "time_multi_edit_move_to_project": "In Projekt verschieben",
    "time_multi_edit_choose_entrie_first": "zuerst Eintrag wählen",
    
    "time_customer_analysis":"Kundenanalyse",
    "time_customer_analysis_duration":"Dauer",
    "time_customer_analysis_duration_time":"[h:m]",
    "time_customer_analysis_duration_dezimal":"dezimal",
    "time_customer_analysis_duration_persondays":"Personentage",
    "time_customer_analysis_to_cache":"Ansicht in Zwischenablage kopieren",
    "time_customer_analysis_reset_filtering":"Filterung aufheben",
    "time_customer_analysis_back_to_customer":"zurück zum Kunden",
    "time_customer_analysis_from":"Von",
    "time_customer_analysis_to":"bis",
    "time_customer_analysis_header_start_date":"Start-Datum",
    "time_customer_analysis_header_end_date":"End-Datum",
    "time_customer_analysis_header_timelisting":"Zeitauflistung",
    "time_customer_analysis_header_projectlisting":"Projektübersicht",
    "time_customer_analysis_show_all":"alle anzeigen",

    "time_project_analysis":"Projektanalyse",
    "time_project_analysis_back_to_project":"zurück zum Projekt",
    "time_project_analysis_from":"Von",
    "time_project_analysis_to":"bis",
    "time_project_analysis_header_start_date":"Start-Datum",
    "time_project_analysis_header_end_date":"End-Datum",
    "time_project_analysis_header_timelisting":"Zeitauflistung",
    "time_project_analysis_show_all":"alle anzeigen",

    "time_analysis_warning_many_entries":"Diese Anfrage enthält {0} Einträge. Wollen Sie sie wirklich jeden Eintrag anzeigen lassen?",
    "time_analysis_warning_many_entries_btn":"anzeigen",

    "usersettings_headline":"Einstellungen",
    "aria_close":"Schließen",
    "usersettings_user_subheader":"Benutzereinstellungen",
    "usersettings_lang_title":"Sprache",
    "usersettings_lang_desc":"Wählen Sie die gewünschte Sprache",
};