import Vue from 'vue'
import i18n from '~/modules/i18n'
import { de } from 'date-fns/locale'
import { format, parse } from 'date-fns'


const locales = { de };


let MyGlobal = {
    install(Vue, options) {

        // 1. add global method or property
        Vue.myGlobalMethod = function() {
            alert('test');
        }

        // add an instance method
        Vue.prototype.$helper = {
            format(date, formatStr = 'PP') {
                return format(date, formatStr, {
                    locale: locales[ i18n.locale ] // or global.__localeId__
                })
            },

            formatDatePicker_title(value) {
                return value ? format( parse(value,'yyyy-MM-dd', new Date(2000, 1, 1)), 'dd. MMM yyyy', { locale: locales[ i18n.locale ] } ) : '';
            },
            formatDatePicker_header(value) {
                if(value.length===4) return value;
                return value ? format( parse(value,'yyyy-MM', new Date(2000, 1, 1)), 'MMM yyyy', { locale: locales[ i18n.locale ] } ) : '';
            },
            formatDatePicker_month(value) {
                return value ? format( parse(value,'yyyy-MM', new Date(2000, 1, 1)), 'MMMM', { locale: locales[ i18n.locale ] } ) : '';
            },
            formatDatePicker_weekday(value) {
                return value ? format( parse(value,'yyyy-MM-dd', new Date(2000, 1, 1)), 'EEE', { locale: locales[ i18n.locale ] } ) : '';
            },

            /**
             * Display escaped HTML special characters the right way
             * @see https://forum.vuejs.org/t/how-to-display-escaped-html-special-characters-the-right-way/33845
             * @param {String} str input string
             * @returns
             */
            decode (str) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = str;
                return textArea.value;
            },

            

            /**
             * Format the time difference and print it in a human readable form.
             * @param {Number} diff time difference in seconds
             * @param {Boolean} showDays should days be shown
             * @returns {String} human readable time difference
             */
            getHumanReadableByDiffSeconds: function(diff,showDays) {
                if(showDays!==undefined && showDays) {
                    var days = Math.floor(diff / 60 / 60 / 24);
                }
                var hours = Math.floor(diff / 60 / 60);
                var minutes = Math.floor(diff / 60);
                var result = '';
                if(showDays!==undefined && showDays && days>0) {
                    result+= days + i18n.t('time_abbr_days') + ' ';
                    hours = hours - (days * 24);
                    minutes = minutes - (days * 24 * 60);
                }
                if(hours>0) {
                    result+= hours+ i18n.t('time_abbr_hours') + ' ';
                    minutes = minutes - (hours * 60);
                }
                if(minutes>0) {
                }
                result+= minutes+ i18n.t('time_abbr_minutes');
                return result;
            },

            
            getArrayFromObject(obj) {
                if(obj) {
                    let result = Object.keys(obj).map(function(key) {
                        return obj[key];
                    });
                    return result;
                }
                return [];
            },
            /**
             * Transforms a php date string into a js date object.
             * @param {string} phpDateString 
             * @return {Date|NULL} The resulting Date-object or NULL
             */
            transformToDate:function(phpDateString) {
                if(typeof phpDateString === 'string' && phpDateString.length>0) {
                    return new Date(parseInt(phpDateString)*1000);//simple -> exact by the second
                    //return new Date(parseInt( phpDateString - (phpDateString%60) )*1000);//advanced -> exact by the minute
                }
                return null;
            },
            transformSheets: function (rawArray) {
                let result = [];

                rawArray.forEach((elem) => {
                    result.push(this.transformSheet(elem));
                });

                //result.sort( (a, b) => b.start.getTime() - a.start.getTime() );
                return result;
            },
            transformSheet: function (raw) {
                let result = {
                    id: raw.id,
                    title: (typeof raw.name === 'string' && raw.name.length>0) ? this.decode(raw.name) : null,
                    start: this.transformToDate(raw.time_meta.start),
                    end: this.transformToDate(raw.time_meta.end),
                    notice: (typeof raw.time_meta.notice === 'string' && raw.time_meta.notice.length>0) ? raw.time_meta.notice : null,
                    break: raw.time_meta.break,
                    projectId: raw.time_meta.project,
                    raw: raw
                };
                return result;
            },
            transformProjects: function (rawArray) {
                let result = [];

                rawArray.forEach((elem) => {
                    result.push(this.transformProject(elem));
                });
                return result;
            },
            transformProject: function (raw) {
                raw.name = this.decode(raw.name);
                if(raw.customer!==null) {
                    raw.customer.name = this.decode(raw.customer.name)
                }
                return raw;
            },
            
            transformCustomers: function (rawArray) {
                let result = [];

                rawArray.forEach((elem) => {
                    result.push(this.transformCustomer(elem));
                });
                return result;
            },
            transformCustomer: function (raw) {
                raw.name = this.decode(raw.name);
                if(raw.projects && raw.projects.length>0) {
                    raw.projects.forEach((elem) => {
                        elem.name = this.decode(elem.name);
                    });
                }
                return raw;
            },

            /**
             * Transforms the formulardata of a sheet into its data.
             * @param {object} sheetForm the formulardata of the sheet
             * @return {object} the timesheet-object
             */
            transformSheetFormToSheet: function(sheetForm) {
                var result = {
                    title: sheetForm.title,
                    status: "publish",
                    time_meta: {
                        start : this.getPhpTimestamp(sheetForm.start),
                        end : this.getPhpTimestamp(sheetForm.end),
                        notice : sheetForm.notice,
                        project : null,
                        break : sheetForm.break,
                    }
                };
                if(sheetForm.project!==null) {
                    result.time_meta.project = sheetForm.project.id
                };
                return result;
            },
            /**
             * Transforms a ISO-date string into a PHP timestamp
             * @param {Date} dateObject a javascript Date object 
             * @returns {null|int} returns a php timestamp (null if isoDateString is NULL)
             */
            getPhpTimestamp: function (dateObject) {
                if(dateObject && typeof dateObject==="object" && dateObject.getTime) {
                    return parseInt(dateObject.getTime() / 1000);
                }
                return null;
            },
            /**
             * Get a project out of the projects array by its id
             * @param {int} projectId The id of the project
             * @param {Array} projects a array of projects
             * @return {Object} project
             */
            getProject: function(projectId, projects) {
                if (projectId && projects) {
                    const result = projects.find(elem => elem.id === projectId);
                    if (result !== undefined) return result;
                }
                return null;
            },
            /**
             * Get a customer out of the customer array by its id
             * @param {int} customerId The id of the customer
             * @param {Array} customers a array of projects
             * @return {Object} project
             */
            getCustomer: function(customerId, customers) {
                if (customerId && customers) {
                    const result = customers.find(elem => elem.id === customerId);
                    if (result !== undefined) return result;
                }
                return null;
            },
            
            /**
             * Group the timesheets by its startday
             * @param {array} array an flat array of timesheets
             */
            groupSheetsByDay(array){
                const result = {}
                array.forEach(item => {
                    var keyDate = new Date(item.start.getFullYear(), item.start.getMonth(), item.start.getDate());
                    var key = keyDate.getTime();
                    if(typeof result[key] === 'undefined') {
                        result[key] = [];
                    }
                    result[key].push(item)
                });
                return result
            },
        }
    }
};

Vue.use(MyGlobal);
