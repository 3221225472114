<template>
    <card :title="$t('login')" class="loginWrap">
        <v-form @submit.prevent="login">
            <v-text-field 
                v-model="form.username" 
                name="username" 
                :placeholder="$t('username')"
                :error-messages="errors.username"
                ></v-text-field>
            <v-text-field 
                v-model="form.password" 
                type="password" 
                name="password" 
                :placeholder="$t('password')"
                :error-messages="errors.username"
                ></v-text-field>
                
            <v-checkbox v-model="remember" name="remember" :label="$t('remember_me')"></v-checkbox>
<!--
                    <router-link :to="{ name: 'password.request' }" class="small ml-auto my-auto">
                        {{ $t('forgot_password') }}
                    </router-link>
-->
            <v-alert dense type="warning" v-for="e in errors" :key="e" v-html="e" class="alert"></v-alert>

            <div class="text-right">
                <v-btn class="primary" type="submit">{{$t('login')}}</v-btn>
            </div>
        </v-form>
    </card>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            status:null,
            statusCodes:{
                sending:'sending',
                success:'success',
                error:'error',
            },
            form: {
                username: '',
                password: '',
            },
            remember: false,
            errors:[]
        }
    },
    methods: {
        login () {
            let that = this;
            if(this.status !== this.statusCodes.sending) {
                this.status = this.statusCodes.sending;
                this.errors = [];
                axios
                    .post('./wp-json/jwt-auth/v1/token', this.form)
                    .then(function(response) {
                        
                        that.$store.dispatch('auth/saveToken', {
                            token: response.data.token,
                            remember: that.remember
                        });

                        // Fetch the user.
                        that.$store.dispatch('auth/fetchUser');
                        that.$store.dispatch('time/resetState');
                        that.$store.dispatch('time/fetchBaseData');

                        // Redirect home.
                        that.$router.push({ name: 'home' });
                    
                        that.status = that.statusCodes.success;
                    })
                    .catch(function(error) {
                        if(error.response) {
                            console.log('error',error.response.data.message);
                            if(error.response.data.message) {
                                that.errors.push(error.response.data.message);
                            }
                        }
                    })
                    .finally(function() {
                        if(that.status === that.statusCodes.sending) {
                            that.status = null;
                        }
                    });
                ;
            }
        }
    }
}
</script>