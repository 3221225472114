<template>
    <v-card class="customerAnalysis">
        <v-card-text>

            <h1 class="headline"><v-icon large>mdi-layers</v-icon> <span >{{ project.name }}</span></h1>
            <div class="subtitle ml-11">
                {{$t('time_project_analysis')}}
            </div>
            <p class="text-center" v-if="loading">
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </p>
            <div v-if="analysis && !loading">
                <p>
                    <router-link class="d-print-none mr-5" :to="{ name:'time.project', params:{ id: project.id } }">{{$t('time_project_analysis_back_to_project')}}</router-link>
                    <router-link v-if="project.customer && project.customer.id" :to="{ name: 'time.customeranalysis', params: { id: project.customer.id } }" class="d-print-none btn btn-success"><v-icon small class="mr-1">mdi-account</v-icon>{{$t('time_customer_analysis')}}</router-link>
                </p>
                <div class="timeSelector">
                    <span class="monthChanger">
                        <v-btn small depressed class="ma-1 d-print-none">
                            <v-icon small @click="setMonth(-1)">mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn small depressed class="ma-1 timeRange" @click="editTimeRange()">
                            <span class="mx-1">{{$t('time_project_analysis_from')}}</span>
                            <dateformat class="monthday" :date="analysisDateStart" format="dd. MMM yyyy"></dateformat>
                            <span class="mx-1">{{$t('time_project_analysis_to')}}</span>
                            <dateformat class="monthday" :date="analysisDateEnd" format="dd. MMM yyyy"></dateformat>
                        </v-btn>
                        <v-btn small depressed class="ma-1 d-print-none">
                            <v-icon small @click="setMonth(1)">mdi-chevron-right</v-icon>
                        </v-btn>
                    </span>

                    <v-btn small depressed class="ma-1 btnReplay d-print-none" @click="showAll()">
                        {{$t('time_project_analysis_show_all')}}
                    </v-btn>

                    <v-btn small :elevation="0" class="ma-1 btnReplay d-print-none" @click="setMonth()">
                        <v-icon small class="mr-2">mdi-replay</v-icon>
                    </v-btn>
                    
                    <div class="analyseFilter ml-3">
                        <v-text-field class="d-print-none" v-model="sheetSearch" outlined dense clearable label="geladene Einträge filtern nach" placeholder="Ergebnis filtern"></v-text-field>
                        <div class="d-none d-print-block" v-if="hasSheetSearchFilter">Einträge gefiltert nach "<strong>{{sheetSearch}}</strong>"</div>
                    </div>
                </div>
                <div v-if="manualSelect.active" class="d-print-none">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 my-2">
                            {{$t('time_project_analysis_header_start_date')}}
                            <v-date-picker full-width v-model="manualSelect.start" color="green lighten-1"
                                :first-day-of-week="1"
                                :title-date-format="$helper.formatDatePicker_title" 
                                :header-date-format="$helper.formatDatePicker_header"
                                :month-format="$helper.formatDatePicker_month" 
                                :weekday-format="$helper.formatDatePicker_weekday"
                                :max="manualSelect.end"
                                reactive
                            ></v-date-picker>
                        </div>
                        <div class="col-lg-4 col-sm-6 my-2">
                            {{$t('time_project_analysis_header_end_date')}}
                            <v-date-picker full-width v-model="manualSelect.end" color="green lighten-1"
                                :first-day-of-week="1"
                                :title-date-format="$helper.formatDatePicker_title" 
                                :header-date-format="$helper.formatDatePicker_header"
                                :month-format="$helper.formatDatePicker_month" 
                                :weekday-format="$helper.formatDatePicker_weekday"
                                :min="manualSelect.start"
                                reactive
                            ></v-date-picker>
                        </div>
                    </div>
                    <p>
                        <v-btn color="success" @click="applyDate()">{{$t('time_confirm')}}</v-btn>
                    </p>
                </div>
                <section class="timelisting">
                    <h2>{{$t('time_project_analysis_header_timelisting')}}</h2>
                    <monthtable :sheets="filteredSheets"></monthtable>
                </section>
                <!--
                <section class="projectlisting">
                    <h2>{{$t('time_project_analysis_header_projectlisting')}}</h2>
                    <projectlist :analysis="analysis"></projectlist>
                </section>-->
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import monthtable from './../components/analysis/monthtable.vue';
import projectlist from './../components/analysis/projectlist.vue';
import dateformat from '~/components/dateformat.vue';

export default {
    components: {
        monthtable,
        projectlist,
        dateformat,
    },
    data: () => ({
        loading: false,
        analysis : null,
        analysisDateStart:null,
        analysisDateEnd:null,
        startDate:0,
        endDate:0,
        project:{
            name:'',
            id:null
        },
        manualSelect: {
            active:false,
            start:null,
            end:null,
        },
        sheetSearch:''
    }),
    mounted: function () {
        this.setMonth();
    },
    computed: {
        ...mapGetters({
            customers: 'time/customers'
        }),
        customer() {
            if(this.project.id!==null && this.customers) {
                console.log('customers',this.customers);
            }
        },
        hasSheetSearchFilter() {
            if(this.sheetSearch===null) return false;
            return this.sheetSearch.trim().length>0;
        },
        filteredSheets() {
            if(!this.analysis) return null;
            if(this.sheetSearch===null) return this.analysis.sheets;
            if(this.sheetSearch.trim().length===0) return this.analysis.sheets;

            let words = this.sheetSearch.trim().split(" ");
            let wordsRegexRaw = '';
            words.forEach(element => {
                wordsRegexRaw+= '(?=.*'+element+')';
            });
            if(wordsRegexRaw.length>0) {
                wordsRegexRaw+='.*$';
            }
            let wordsRegex = new RegExp(wordsRegexRaw,'gi');
            return this.analysis.sheets.filter( (item) => {
                return item.name.match(wordsRegex);
            });
        }
    },
    methods: {
        getDateIso(date) {
            return ('0'+date.getFullYear()).slice(-4)+'-'+ ('0'+(date.getMonth()+1)).slice(-2)+'-'+('0'+date.getDate()).slice(-2)
        },
        showAll() {
            this.getData(true);
        },
        getData(showAllEntries) {
            if(!this.loading) {
                this.loading = true;
                let requestParams = {
                    project : this.$route.params.id,
                    start: this.getDateIso(this.startDate),
                    end: this.getDateIso(this.endDate),
                };
                if(showAllEntries===true) {
                    requestParams.start = 'all';
                    requestParams.end = 'all';
                }
                axios.get('./wp-json/tm_time/v1/projectanalysis', { params: requestParams })
                    .then(response => {
                        this.loading = false;
                        this.analysis = response.data;
                        this.project.name = this.analysis.project.name;
                        this.project.id = this.analysis.project.id;
                        this.project.customer = this.analysis.project.customer;
                        this.analysisDateStart = new Date(this.analysis.meta.timeStart);
                        this.analysisDateEnd = new Date(this.analysis.meta.timeEnd);
                        this.analysisDateEnd.setTime( this.analysisDateEnd.getTime() - 1000 );//substract a second
                    })
                    .catch(error => {
                        this.loading = false;
                    });
            }
        },
        setMonth(value) {
            var current = new Date();
            current.setMilliseconds(0);
            current.setSeconds(0);
            current.setMinutes(0);
            current.setHours(0);
            if(value==-1) {
                current = new Date(this.startDate);
                current.setTime(current.getTime() - (1000 * 60 * 60 * 24) );//substract a day
            }
            if(value==1) {
                current = new Date(this.endDate);
                current.setTime(current.getTime() + (1000 * 60 * 60 * 24));//add a day
            }
            this.startDate = new Date(current.getFullYear(), current.getMonth(), 1);
            this.endDate = new Date(current.getFullYear(), current.getMonth() + 1, 0);
            this.getData();
        },
        editTimeRange() {
            this.manualSelect.active = !this.manualSelect.active;
            this.manualSelect.start = this.getDateIso(this.startDate);
            this.manualSelect.end = this.getDateIso(this.endDate);
        },
        applyDate() {
            this.startDate = new Date(
                this.manualSelect.start.slice(0,4),//year
                this.manualSelect.start.slice(5,7) - 1,//month
                this.manualSelect.start.slice(8,10),//day
            );
            this.endDate = new Date(
                this.manualSelect.end.slice(0,4),//year
                this.manualSelect.end.slice(5,7) - 1,//month
                this.manualSelect.end.slice(8,10),//day
            );
            this.manualSelect.active = false;
            this.getData();
        },
    }
}
</script>
