import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const mutationTypes = {};
const storeObj = {
    modules: {}//fill modules later
};


// Load auth module
const auth = require('~/modules/auth/store/');
storeObj.modules.auth = auth.storeModule;
Object.assign(mutationTypes, auth.mutationTypes);


// Load lang module
const i18n = require('~/modules/i18n/store/');
storeObj.modules.lang = i18n.storeModule;
Object.assign(mutationTypes, i18n.mutationTypes);


// Load lang module
const time = require('./module-time.js');
storeObj.modules.time = time.storeModule;
Object.assign(mutationTypes, time.mutationTypes);


// Load online module
const online = require('./module-online.js');
storeObj.modules.online = online.storeModule;
Object.assign(mutationTypes, online.mutationTypes);

const store = new Vuex.Store(storeObj);
store.dispatch('setupOnlineState');//setup the online-module
export default store;