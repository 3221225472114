<template>
    <v-dialog v-model="dialog" max-width="640">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on:on }" ><!-- make this button overrideable -->
                <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
            </slot>
        </template>
        <v-card class="datetimePicker">
            <v-card-text class="py-3 px-6">

                <h3 class="text-center">{{$helper.format(value,'eeee dd.MM.yyyy - H:mm')}} Uhr</h3>
                <v-row>
                    <div class="col-xs-12 col-sm-6">
                        <v-date-picker v-model="dateValue" 
                            full-width
                            :first-day-of-week="1"
                            :title-date-format="$helper.formatDatePicker_title" 
                            :header-date-format="$helper.formatDatePicker_header"
                            :month-format="$helper.formatDatePicker_month" 
                            :weekday-format="$helper.formatDatePicker_weekday"
                            ></v-date-picker>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <v-time-picker v-model="timeValue" format="24hr" full-width></v-time-picker>
                    </div>
                </v-row>
                <v-row>
                    <v-col class="text-right">
                        <v-btn depressed @click="dialog=false">abbrechen</v-btn>
                        <v-btn depressed color="primary" @click="close()">übernehmen</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { format,parse } from 'date-fns'
export default {
    components: {},
    props: {
        value: {
            type:Date,
            default: null
        }
    },
    data: () =>({
        dialog: false,
        dateValue: null,
        timeValue: null,
    }),
    mounted() {
        this.applyValues();
    },
    watch: {
        value(oldValue, newValue) {
            this.applyValues();
        },
        dialog(oldValue, newValue) {
            if(oldValue===false && newValue===true) this.applyValues();
        },
    },
    methods: {
        close() {
            let result = parse(this.dateValue+' '+this.timeValue , 'yyyy-MM-dd H:mm', new Date(2000, 1, 1));
            this.$emit('input', result);
            this.dialog = false;
        },
        applyValues() {
            this.dateValue = null;
            this.timeValue = null;
            if(this.value!==null) {
                this.dateValue = this.$helper.format( this.value, 'yyyy-MM-dd' );
                this.timeValue = this.$helper.format( this.value, 'H:mm' );
            }
        },
    }
}
</script>