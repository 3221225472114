<template>
    <form @submit.prevent="submit" class="timesheetForm">
        <p>
            <v-text-field
                v-model="sheetForm.title" 
                :id="'sheetTitleTextField_'+_uid"
                autofocus
                :class="'titleMenuParent titleMenuParent'+_uid"
                style="position:relative;"
                name="title" 
                ref="firstInput" 
                :label="$t('time_log_title')" 
                append-icon="mdi-view-list"
                @click:append="handlePreviousTitles"
                required></v-text-field>
            
            <v-menu v-model="titleMenu" :attach="'.titleMenuParent'+_uid+' .v-input__append-inner'" dark left bottom nudge-top="-30px" nudge-right="24px">
                <lastSheetTitlesList :sheets="projectSheets" @useTitle="useTitle"></lastSheetTitlesList>
            </v-menu>
        </p>
        <v-row>
            <div class="col-md-6">
                <datetime-editor v-model="sheetForm.start" :not-after="sheetForm.end" type="start"></datetime-editor>
            </div>
            <div class="col-md-6" v-if="sheetForm.end">
                <datetime-editor v-model="sheetForm.end" :not-before="sheetForm.start" type="end"></datetime-editor>
            </div>
            <div class="col-md-6" v-if="!sheetForm.end">
                <v-btn class="yellow darken-2 stopButton" dark block depressed @click="stopSheet">
                    <v-icon>mdi-flag</v-icon> {{$t('time_stop')}}
                </v-btn>
            </div>
        </v-row>

        <v-alert v-if="!validEndDate" color="error" dense text class="text-center">
            {{$t('time_end_date_before_start_date')}}
        </v-alert>

        <v-layout mb-3>
            <div class="mx-7"></div>
            <v-spacer></v-spacer>
            <div v-if="sheetForm.end" class="currentlyRunningWrap">
                <v-icon>mdi-update</v-icon> <timedifference :start="sheetForm.start" :end="sheetForm.end" :break="sheetForm.break"></timedifference>
            </div>
            <div v-if="!sheetForm.end">
                <currently-running :sheet="sheetForm"></currently-running>
            </div>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary" fab><v-icon dark>mdi-content-save</v-icon></v-btn>
        </v-layout>

        <p>
            <v-textarea
                filled
                v-model="sheetForm.notice" name="notice" 
                :label="$t('time_log_notice')"></v-textarea>
        </p>

        <p>
            <v-text-field v-model="sheetForm.break" name="break" :label="$t('time_log_break')"></v-text-field>
        </p>
        <div class="alert alert-danger mb-3" role="alert" v-if="!validBreakTime">
            {{$t('time_valid_break_time')}}
        </div>
        
        <selectProject :project="sheetForm.project" @after="selectProject"></selectProject>
        
        <div class="alert alert-danger mb-3" role="alert" v-if="validation.project">
            {{$t('time_alert_choose_a_project')}}
            
            <button type="button" class="close" aria-label="Close" @click="validation.project=false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="row">
            <div class="col-md-6">
                
            </div>
            <div class="col-md-6 text-right">
                <template v-if="sheetForm.id">
                    <v-btn color="secondary" @click="deleteSheet"><v-icon dark>mdi-delete</v-icon><!--{{$t('time_delete_sheet')}}--></v-btn>
                    <v-btn color="primary" type="submit"><v-icon dark class="mr-2">mdi-content-save</v-icon>{{$t('time_confirm')}}</v-btn>
                </template>
                <template v-else>
                    <v-btn color="primary" type="submit">{{$t('time_create_new_sheet')}}</v-btn>
                </template>
            </div>
        </div>
    </form>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import axios from 'axios';
import timedifference from './timedifference.vue';
import currentlyRunning from './currentlyRunning.vue';
import datetimeEditor from './datetimeEditor.vue';
import selectProject from './selectProject.vue';
import lastSheetTitlesList from './lastSheetTitlesList.vue';

export default {
    components: { timedifference, currentlyRunning, selectProject, datetimeEditor, lastSheetTitlesList },
    props: [
        'data'
    ],
    data: () => ({
        titleMenu:false,
        projectSheets:[],
        sheetForm: {
            id:null,
            start:new Date(),
            end:null,
            title:"",
            notice:"",
            break:0,
            project:null
        },
        validation:{
            project:false,
        },
    }),
    methods: {
        openSettings() {
            document.timesheetmanager.openSettings();
        },
        stopSheet() {
            this.sheetForm.end = new Date();
        },
        useTitle(title) {
            this.sheetForm.title = title;
            this.titleMenu=false;
        },
        loadSheet() {
            if(this.data.id) this.sheetForm.id = parseInt(this.data.id);
            if(this.data.start) this.sheetForm.start = new Date(this.data.start);
            if(this.data.end) this.sheetForm.end = new Date(this.data.end);
            if(this.data.title) this.sheetForm.title = this.data.title;
            if(this.data.notice) this.sheetForm.notice = this.data.notice;
            if(this.data.break) this.sheetForm.break = this.data.break;

            if(this.data.projectId && this.data.project===undefined && this.projects) {
                this.selectProject(this.$helper.getProject(this.data.projectId, this.projects));
            }

            let that = this;
            this.$store.watch( 
                state => this.$store.getters['time/projects'],
                (val)=>{
                    //console.log('lazy projects changed');
                    that.selectProject( this.$helper.getProject(that.data.projectId, that.projects) );
                }
            );
        },
        async selectProject(p) {
            this.sheetForm.project = p;
            if(p && p.id) {
                await this.$store.dispatch('time/fetchProjectSheets', { id:p.id } );// Fetch the customers
                let sheets = this.$store.getters['time/getProjectSheets'](p.id);
                if(sheets && sheets!==null) {
                    let that = this;
                    this.projectSheets = sheets.filter(entry => {
                            return entry.id != that.$route.params.id;
                        }).slice(0,10);
                } else {
                    this.projectSheets = [];    
                }
            } else {
                this.projectSheets = [];
            }
        },
        handlePreviousTitles() {
            this.titleMenu=true;
        },
        isFormValid() {
            var valid = true;
            this.validation.project = false;
            if(!this.sheetForm.project) {
                this.validation.project = true;
                valid = false;
            }
            if(!this.validEndDate) {
                valid = false;
            }
            return valid;
        },
        submit: async function() {
            let targetUrl = './wp-json/tm_time/v1/sheet';
            let editMode = false;
            if(!this.isFormValid()) {
                return;
            }
            
            const requestData = this.$helper.transformSheetFormToSheet(this.sheetForm);
            if(this.sheetForm.id!==null) {
                editMode = true;
                targetUrl+= '/'+this.sheetForm.id;
                requestData.id=this.sheetForm.id;
            }
            const { data } = await axios.post(targetUrl, requestData);
            if(data.id) {
                if(editMode) {
                    let newSheet = this.$helper.transformSheet(data);
                    if(newSheet.projectId) {
                        this.$router.push({ name: 'time.project', params:{ id : newSheet.projectId } });//redirect to createsheet
                    } else {
                        this.$router.push({ name: 'time.sheet', params:{ id : newSheet.id } });//redirect to createsheet
                    }
                } else {
                    this.$router.push({ name: 'time' });//redirect to createsheet
                }
            }
        },
        deleteSheet : async function() {
            if(confirm( this.$t('time_sheet_confirm_delete') )) {
                const {data} = await axios.delete(
                    './wp-json/tm_time/v1/sheet/'+this.$route.params.id, { 
                    params: {
                        id:this.$route.params.id, 
                        force: true 
                    }
                });

                // Redirect 
                this.$router.push({ name: 'time.sheets' });
            }
        },
        setDateTo(targetValue,baseValue) {
            if(targetValue===null) {
                return baseValue;
            }
            if(baseValue===null) {
                return targetValue;
            }
            let newValue = new Date();
            newValue.setTime(baseValue.getTime());
            newValue.setDate(targetValue.getDate());
            newValue.setMonth(targetValue.getMonth());
            newValue.setFullYear(targetValue.getFullYear());
            newValue.setMilliseconds(0);
            return newValue;
        },
        setTimeTo(targetValue,baseValue) {
            if(targetValue===null) {
                return baseValue;
            }
            if(baseValue===null) {
                return targetValue;
            }
            let newValue = new Date();
            newValue.setTime(baseValue.getTime());
            newValue.setHours(targetValue.getHours());
            newValue.setMinutes(targetValue.getMinutes());
            newValue.setSeconds(targetValue.getSeconds());
            newValue.setMilliseconds(0);
            return newValue;
        }
    },
    created() {
        let that = this;
        this.loadSheet();
        this.$nextTick(() => {
            //prefocus on the first Input (defined with ref)
            this.$refs.firstInput.focus();

            //select text if still default text
            if(that.sheetForm.title===that.$t('time_new_timelog_default_title')) {
                setTimeout(() => {
                    let elem = document.getElementById('sheetTitleTextField_'+that._uid);
                    elem.setSelectionRange(0,elem.value.length);
                },100);
            }
        });
    },
    computed: { 
        ...mapGetters({
            projects: 'time/projects'
        }),
        filteredProjectSheets(){
            let that = this;
            if(!this.projectSheets || this.projectSheets===null) {
                return [];
            }
            return this.projectSheets.filter(entry => {
                return entry.id !== that.$route.params.id;
            }).slice(0,5);
        },
        /**
         * check whether the end-date is before the start date
         */
        validEndDate(){
            if(this.sheetForm.end===null) {
                return true;
            }
            if(this.sheetForm.end < this.sheetForm.start) {
                return false;
            }
            return true;
        },
        validBreakTime() {
            if(this.validEndDate) {
                //TODO
            }
            return true;
        },
        selectedProject:function() {
            if(this.sheetForm.project===null) {
                return this.$t('time_please_choose');
            } else {
                return this.sheetForm.project.name;
            }
        }
    }
}
</script>