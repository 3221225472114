<template>
    <div class="text-center mt-10">
        <template v-if="authenticated">
            <div class="title m-5">
                <h1>{{ title }}</h1>
                <router-link class="v-btn v-btn--contained theme--light v-size--default primary" :to="{ name: 'time' }">
                    {{ $t('time_start') }}
                </router-link>
            </div>
            <template v-if="user.roles[0]!=='administrator'">
                {{ $t('no_access_rights') }}
            </template>
        </template>
        <template v-else>
            <router-link class="v-btn v-btn--contained theme--light v-size--default primary" :to="{ name: 'login' }">
                {{ $t('login') }}
            </router-link>
            <router-link class="v-btn v-btn--contained theme--light v-size--default primary" :to="{ name: 'register' }">
                {{ $t('register') }}
            </router-link>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    layout: 'basic',

    data: () => ({
        title: window.timesheetapp.appName,
        testdata:[]
    }),
    created: function () {
        if(this.$store.getters['auth/check']===true) {
            console.log('redirect to page');
            this.$router.push({ name: 'time' });
        }
    },
    computed: mapGetters({
        authenticated: 'auth/check',
        user: 'auth/user'
    }),
    methods: {
        
    }
}
</script>

<style scoped>
.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.title {
    font-size: 85px;
}
</style>
