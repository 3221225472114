<template>
    <span v-html="versionDate"></span>
</template>


<script>
export default {
    computed: {
        versionDate() {
            let value = TIMESHEET_VERSION_DATE
            if(value!=='DYNAMIC') {
                value = new Date(value);
                value = '<span class="date">'+ ('0'+value.getDate()).slice(-2)+'.'+('0'+(value.getMonth()+1)).slice(-2)+'.'+value.getFullYear()+'</span> <span class="time">'+('0'+value.getHours()).slice(-2)+':'+('0'+value.getMinutes()).slice(-2)+'</span>';
            }
            return value;
        }
    }
}
</script>