<template>
    <card :title="$t('time_create_new_project')">
        <projectform v-if="project" :data="project"></projectform>
        <div class="text-right mt-2">
            <v-btn text @click="$router.go(-1)">{{$t('time_back')}}</v-btn>
        </div>
    </card>
</template>

<script>
import axios from 'axios';
import projectform from './../components/projectForm.vue';

export default {
    components: {
        projectform : projectform
    },
    data: () => ({
        project:null
    }),
    created: function () {
        this.getData();
    },
    computed:{},
    methods: {
        getData : async function() {
            const {data} = await axios.get('./wp-json/tm_time/v1/project/'+this.$route.params.id);
            this.project = this.$helper.transformProject(data);
        }
    },
}
</script>