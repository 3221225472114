<template>
    <span class="dateformat">{{output}}</span>
</template>

<script>
import { format } from 'date-fns'
export default {
    name: 'dateformat',
    props: {
        date: { type: Date, default: null },
        format: { type: String, default: 'dd.MM.yyyy HH:mm' },
    },
    computed : {
        output : function() {
            if (this.date) {
                return this.$helper.format(this.date, this.format);
            }
        }
    }
}
</script>