<template>
  <v-card class="card">
    <v-card-title v-if="title && icon" class="card-header">
      <div class="media">
        <v-icon class="mr-3">{{icon}}</v-icon>
        <div class="media-body">
          <span class="cardTitle">{{ title }}</span>
          <small class="subtitle" v-if="subtitle">{{ subtitle }}</small>
        </div>
      </div>
      <div v-if="title && !icon" class="card-header">
        <span class="cardTitle">{{ title }}</span>
        <small class="subtitle" v-if="subtitle">{{ subtitle }}</small>
      </div>
    </v-card-title>

    <v-card-text class="card-body">
      <slot/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Card',

  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default:null },
    icon: { type: String, default:null }
  }
}
</script>
