<template>
    <v-app>
        <appbar class="d-print-none"></appbar>
        <v-main id="page" class="mb-5">
            <main id="main">
                <router-view :key="$route.fullPath"></router-view>
            </main>
        </v-main>
        <!--<pagefooter></pagefooter>-->

        <MessageConfirm entrypoint="time"></MessageConfirm>
        <MessageAlert entrypoint="time"></MessageAlert>
        <MessageSnackBar entrypoint="time"></MessageSnackBar>
    </v-app>
</template>


<script>
import appbar from './components/appbar.vue';
import MessageConfirm from './components/MessageConfirm.vue';
import MessageAlert from './components/MessageAlert.vue';
import MessageSnackBar from './components/MessageSnackBar.vue';
export default {
    components:{ appbar, MessageConfirm, MessageAlert, MessageSnackBar },
    created() {
        this.init();
    },
    methods: {
        init: async function() {
            this.$store.dispatch('time/fetchBaseData');// Fetch the customers
        }
    },
}
</script>