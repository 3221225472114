<template>
    <v-dialog v-model="userDialog" fullscreen transition="dialog-bottom-transition">
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </template>

        <v-card class="pa-0" v-if="userDialog">
            <!--<v-card-title></v-card-title>-->
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{$t('usersettings_headline')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="closeDialog" :aria-label="$t('aria_close')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                
                <v-list subheader>
                    <v-subheader>{{$t('usersettings_user_subheader')}}</v-subheader>
                    <v-list-item class="optimizeHeight">
                        <v-list-item-action>
                            <v-icon>mdi-translate</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{$t('usersettings_lang_title')}}</v-list-item-title>
                            <v-list-item-content>
                                <div>
                                    <div>{{$t('usersettings_lang_desc')}}</div>
                                    <v-select
                                        :items="locales"
                                        item-text="name"
                                        :value="currentLocale"
                                        return-object
                                        outline
                                        @change="setLocale"
                                    ></v-select>
                                </div>
                            </v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon>mdi-security</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Password</v-list-item-title>
                            <v-list-item-content>TODO Passwort ändern</v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon>mdi-subdirectory-arrow-right</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-content>
                                <div><v-btn class="primary" @click.prevent="gotoLogout">{{ $t('logout') }}</v-btn></div>
                            </v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="user.role==='admin'">
                        <v-list-item-action>
                            <v-icon>settings</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-content>
                                <div><v-btn class="primary" @click.prevent="gotoUserroles">{{ $t('userroles') }}</v-btn></div>
                            </v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <default-titles></default-titles>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { setI18nLocale } from "~/modules/i18n";
import defaultTitles from './defaultTitles.vue';
export default {
    components: { defaultTitles },
    data: () => ({
        userDialog: false,//open or close the dialog

        notifications: false,
        sound: true,
        widgets: false
    }),
    computed:{
        ...mapGetters({
            user: 'auth/user',
            locale: "lang/locale",
            locales: "lang/locales",
        }),
        currentLocale() {
            if (this.locales && this.locale) {
                var that = this;
                for (var i = 0; i < this.locales.length; i++) {
                    if (this.locales[i].id == this.locale) {
                        return this.locales[i];
                    }
                }
            }
        }
    },
    mounted() {
        if(document.timesheetmanager===undefined) document.timesheetmanager = {};
        document.timesheetmanager.openSettings = this.openSettings;
    },
    methods:{
        openSettings() {
            this.userDialog = true;
        },
        setLocale(locale) {
            setI18nLocale(locale.id);
        },
        async gotoLogout() {
            // Log out the user.
            await this.$store.dispatch('auth/logout')
            await this.$store.dispatch('time/resetState');
            this.userDialog = false;

            // Redirect to login.
            this.$router.push({ name: 'login' })
        },
        gotoUserroles() {
            this.userDialog = false;
            this.$router.push({ name: 'userroles' })
        },
        closeDialog() {
            this.userDialog = false;
        }
    }

};
</script>