<template>
    <v-card class="customerAnalysis">
        <v-card-title class="justify-space-between align-start">
            <div :class="{ 'd-print-none' : hideHeaderOnPrint }">
                <h1 class="headline"><v-icon style="vertical-align: text-bottom;" large>mdi-account</v-icon> <span >{{ customer.name }}</span></h1>
                <div class="subtitle ml-11">
                    {{$t('time_customer_analysis')}}
                </div>
            </div>
            <div class="d-print-none">
                <v-switch small dense label="Kopfbereich bei Druck ausblenden?" v-model="hideHeaderOnPrint"></v-switch>
            </div>
        </v-card-title>
        <v-card-text>
            <p class="text-center" v-if="loading">
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </p>
            <div v-if="analysis && !loading">
                <p>
                    <router-link class="d-print-none" :to="{ name:'time.customer', params:{ id:customer.id } }">{{$t('time_customer_analysis_back_to_customer')}}</router-link>
                </p>
                <div class="timeSelector">
                    
                    <span class="monthChanger">
                        <v-btn small class="ma-1 d-print-none" @click="setMonth(-1)">
                            <v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn small class="ma-1 timeRange" @click="editTimeRange()">
                            <span class="mx-1">{{$t('time_customer_analysis_from')}}</span>
                            <dateformat class="monthday" :date="analysisDateStart" format="dd. MMM yyyy"></dateformat>
                            <span class="mx-1">{{$t('time_customer_analysis_to')}}</span>
                            <dateformat class="monthday" :date="analysisDateEnd" format="dd. MMM yyyy"></dateformat>
                        </v-btn>
                        <v-btn small class="ma-1 d-print-none" @click="setMonth(1)">
                            <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                    </span>

                    <v-btn small class="ma-1 btnReplay d-print-none" @click="showAll()">
                        {{$t('time_customer_analysis_show_all')}}
                    </v-btn>

                    <v-btn small class="ma-1 btnReplay d-print-none" @click="setMonth()">
                        <v-icon small>mdi-replay</v-icon>
                    </v-btn>
                </div>


                <section class="row my-3 d-print-none">
                    <div class="col analyseFilter" :class="{ hasSearchFilter: hasSheetFilter }">
                        <v-text-field class="d-print-none" hide-details v-model="sheetSearch" outlined dense clearable label="geladene Einträge filtern nach" placeholder="Ergebnis filtern"></v-text-field>
                        <div class="d-none d-print-block" v-if="hasSheetFilter">Einträge gefiltert nach "<strong>{{sheetSearch}}</strong>"</div>
                    </div>
                    <div class="col ">
                        <v-autocomplete 
                            v-model="projectFilter" 
                            :items="projectList"
                            label="nach Projektfiltern"
                            item-text="name" 
                            item-value="id"
                            outlined dense hide-details chips multiple deletable-chips small-chips
                            ></v-autocomplete>
                    </div>
                </section>
                
                <div v-if="manualSelect.active" class="d-print-none">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 my-2">
                            {{$t('time_customer_analysis_header_start_date')}}
                            <v-date-picker full-width v-model="manualSelect.start" color="green lighten-1"
                                :first-day-of-week="1"
                                :title-date-format="$helper.formatDatePicker_title" 
                                :header-date-format="$helper.formatDatePicker_header"
                                :month-format="$helper.formatDatePicker_month" 
                                :weekday-format="$helper.formatDatePicker_weekday"
                                :max="manualSelect.end"
                                reactive
                            ></v-date-picker>
                        </div>
                        <div class="col-lg-4 col-sm-6 my-2">
                            {{$t('time_customer_analysis_header_end_date')}}
                            <v-date-picker full-width v-model="manualSelect.end" color="green lighten-1"
                                :first-day-of-week="1"
                                :title-date-format="$helper.formatDatePicker_title" 
                                :header-date-format="$helper.formatDatePicker_header"
                                :month-format="$helper.formatDatePicker_month" 
                                :weekday-format="$helper.formatDatePicker_weekday"
                                :min="manualSelect.start"
                                reactive
                            ></v-date-picker>
                        </div>
                    </div>
                    <p>
                        <v-btn color="success" @click="applyDate()">{{$t('time_confirm')}}</v-btn>
                    </p>
                </div>
                <section class="timelisting">
                    <h2>{{$t('time_customer_analysis_header_timelisting')}}</h2>
                    <Monthtable @filterByProject="filterByProject" :sheets="filteredSheets"></Monthtable>
                </section>
                <section class="projectlisting pt-10 mt-10" v-if="!hasSheetFilter">
                    <h2>{{$t('time_customer_analysis_header_projectlisting')}}</h2>
                    <Projectlist :projects="analysis.projects"></Projectlist>
                </section>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios';
import Monthtable from '../components/analysis/monthtable.vue';
import Projectlist from '../components/analysis/projectlist.vue';
import dateformat from '~/components/dateformat.vue';

export default {
    components: {
        Monthtable,
        Projectlist,
        dateformat,
    },
    data: () => ({
        loading: false,
        analysis : undefined,
        analysisDateStart:undefined,
        analysisDateEnd:undefined,
        startDate:0,
        endDate:0,
        customer:{
            name:'',
            id:null
        },
        manualSelect: {
            active:false,
            start:null,
            end:null,
        },

        hideHeaderOnPrint:false,
        sheetSearch:'',
        projectFilter:[],
    }),
    computed:{
        projectList() {
            if(!this.analysis) return [];
            return Object.values(this.analysis.projects);
        },
        hasSheetFilter() {
            let result = false;
            if(this.sheetSearch!==null && this.sheetSearch.trim().length>0) result = true;
            if(this.projectFilter.length>0) result = true;
            return result;
        },
        filteredSheets() {
            if(!this.analysis) return null;

            let result = this.analysis.sheets.slice();
            let searchtext = '';
            if(this.sheetSearch!==null) searchtext = this.sheetSearch.trim();

            if(searchtext.length>0) {
                let words = searchtext.trim().split(" ");
                let wordsRegexRaw = '';
                words.forEach(element => {
                    wordsRegexRaw+= '(?=.*'+element+')';
                });
                if(wordsRegexRaw.length>0) {
                    wordsRegexRaw+='.*$';
                }
                let wordsRegex = new RegExp(wordsRegexRaw,'gi');
                result = result.filter( item => item.name.match(wordsRegex) );
            }

            //project filter
            if(this.projectFilter.length>0) {
                result = result.filter(item => this.projectFilter.includes(item.projectId));
            }

            return result;
        }
    },
    created: function () {
        this.setMonth();
    },
    methods: {
        filterByProject(data) {
            if(!this.projectFilter.includes(data)) {
                this.projectFilter.push(data);
            }
        },
        getDateIso(date) {
            return ('0'+date.getFullYear()).slice(-4)+'-'+ ('0'+(date.getMonth()+1)).slice(-2)+'-'+('0'+date.getDate()).slice(-2)
        },
        showAll() {
            this.getData(true);
        },
        getData(showAllEntries) {
            let that = this;
            if(!that.loading) {
                that.loading = true;
                let requestParams = {
                    customer : that.$route.params.id,
                    start: this.getDateIso(this.startDate),
                    end: this.getDateIso(this.endDate),
                };
                if(showAllEntries===true) {
                    requestParams.start = 'all';
                    requestParams.end = 'all';
                }

                axios.get('./wp-json/tm_time/v1/customeranalysis', { params: requestParams })
                    .then(function (response) {
                        that.analysis = response.data;
                        that.customer.name = that.analysis.customer.name;
                        that.customer.id = that.analysis.customer.id;
                        that.analysisDateStart = new Date(that.analysis.meta.timeStart);
                        that.analysisDateEnd = new Date(that.analysis.meta.timeEnd);
                        that.analysisDateEnd.setTime( that.analysisDateEnd.getTime() - 1000 );//substract a second
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function() {
                        that.loading = false;
                    });
            }
        },
        setMonth(value) {
            var current = new Date();
            current.setMilliseconds(0);
            current.setSeconds(0);
            current.setMinutes(0);
            current.setHours(0);
            if(value==-1) {
                current = new Date(this.startDate);
                current.setTime(current.getTime() - (1000 * 60 * 60 * 24) );//substract a day
            }
            if(value==1) {
                current = new Date(this.endDate);
                current.setTime(current.getTime() + (1000 * 60 * 60 * 24));//add a day
            }
            this.startDate = new Date(current.getFullYear(), current.getMonth(), 1);
            this.endDate = new Date(current.getFullYear(), current.getMonth() + 1, 0);
            this.getData();
        },
        editTimeRange() {
            this.manualSelect.active = !this.manualSelect.active;
            this.manualSelect.start = this.getDateIso(this.startDate);
            this.manualSelect.end = this.getDateIso(this.endDate);
        },
        applyDate() {
            this.startDate = new Date(
                this.manualSelect.start.slice(0,4),//year
                this.manualSelect.start.slice(5,7) - 1,//month
                this.manualSelect.start.slice(8,10),//day
            );
            this.endDate = new Date(
                this.manualSelect.end.slice(0,4),//year
                this.manualSelect.end.slice(5,7) - 1,//month
                this.manualSelect.end.slice(8,10),//day
            );
            this.manualSelect.active = false;
            this.getData();
        },
    }
}
</script>
