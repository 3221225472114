<!--
HOW TO USE
add MessageAlert once anywhere inside your vuetify app. 
On a place where it always get loaded, i.e. before the closing "v-app"
<MessageAlert entrypoint="my_entrypoint"></MessageAlert>

Now you can use it anywhere in your code:
### promise based
    document.my_entrypoint.openAlert({ message:"Thats the text" }).then(() => {
      console.log('Alert closed');
    });

OR
### syncron

    async test() {
      await document.my_entrypoint.openAlert({ title: "Thats the title", message:"thats the text" });
      console.log('Alert closed');
    }

### Parameters 
- title (optional)
- message
- btn_accept (optional)
- type (optional) a class variant added to the dialog
-->

<template>
  <v-dialog v-model="dialog" :content-class="dialogClass" persistent max-width="400">
    <v-card>
      <v-card-title class="d-block text-center" v-if="title">{{title}}</v-card-title>
      <v-card-text v-html="message" class="text-center"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" @click="accept">{{btn_accept}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props:{
    entrypoint: {//the EntryPoint defines where the functions should be available
      required:true,
      type:String
    }
  },
  data:() => ({
    dialog:false,
    message:"",
    title:"",
    btn_accept: "",
    type:""
  }),
  computed: {
    dialogClass() {
      return 'messageAlertWrap messageAlertTheme-'+this.type;
    }
  },
  mounted() {
    this.reset();
    document.addEventListener(this.entrypoint+'_open_message_alert', this.handleOpenEvent, { once:false /* handle listener only once*/ });
    if(document[this.entrypoint]===undefined) document[this.entrypoint] = {};
    document[this.entrypoint].openAlert = this.publicFunction;
  },
  beforeDestroy() {
    document.removeEventListener(this.entrypoint+'_open_message_alert', this.handleCloseEvent);
    document[this.entrypoint].openAlert = undefined;
  },
  methods:{
    reset() {
      this.dialog = false;
      this.message = "";
      this.title = "Hinweis";
      this.btn_accept = "OK";
      this.type = "default";
    },
    handleOpenEvent(event) {
      this.dialog = true;
      this.message = event.detail.message;
      if(event.detail.title) this.title = event.detail.title;
      if(event.detail.btn_accept) this.btn_accept = event.detail.btn_accept;
      if(event.detail.type) this.type = event.detail.type;
    },
    accept() {
      var event = new CustomEvent(this.entrypoint+'_message_alert_close', { detail: { 'type': 'accept' }});
      document.dispatchEvent(event);
      this.reset();
    },

    /**
     * This function will be available via document.<your-entrypoint>.openAlert
     */
    publicFunction(data) {
      //send open event to MessageAlert-component 
      document.dispatchEvent(
        new CustomEvent(this.entrypoint+'_open_message_alert', { detail: data })
      );

      return new Promise((resolve) => {//return promise so the caller can wait for it
        //wait for close event from MessageAlert-component
        document.addEventListener(this.entrypoint+'_message_alert_close', (eventData) => {
          console.log('alert close',eventData);
          resolve();
        }, { once: true /* handle listener only once*/ });

      });
    }
  }
}
</script>