<template>
    <section class="timesheetmanagerWrapper">
        <v-container fluid class="timeMainContent">
            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>
        </v-container>
    </section>
</template>

<script>
export default {
    middleware: 'auth',
    components: {},
    data: () => ({ }),
    computed: {}
}
</script>
