<template>
    <v-card class="singleSheet" v-if="sheet">
        <v-card-text>
            <h1 class="headline"><span >{{ sheet.title }}</span></h1>
            <div class="subtitle">
                {{$t('time_sheet')}}
            </div>
            <p v-if="project">
                <span class="project pr-5">
                    <v-icon>mdi-layers</v-icon>
                    <router-link :to="{ name:'time.project', params:{id:project.id} }">{{project.name}}</router-link>
                </span>
                <span class="customer">
                    <v-icon>mdi-account</v-icon>
                    <router-link :to="{ name:'time.customer', params:{id:project.customer.id} }">{{project.customer.name}}</router-link>
                </span>
            </p>
            <p v-if="sheet.end">
                <v-icon>mdi-update</v-icon><timedifference :start="sheet.start" :end="sheet.end" :break="sheet.break"></timedifference>
            </p>
            <p class="timeInfo">
                <span><dateformat :date="sheet.start" format="dd.MM.yyyy" class="from date" /> <dateformat :date="sheet.start" format="HH:mm" class="from time" /></span>
                <span v-if="sheet.end">bis <dateformat v-if="!sameDay()" :date="sheet.end" format="dd.MM.yyyy" class="till date" /> <dateformat :date="sheet.end" format="HH:mm" class="till time" /></span>
            </p>
            
            <p v-if="!sheet.end">
                <currently-running :sheet="sheet"></currently-running><button type="button" class="btn btn-warning" @click="editSheet(true)"><v-icon>mdi-flag</v-icon> {{$t('time_stop')}}</button>
            </p>


            <p v-if="sheet.notice" class="notice" v-html="sheet.notice"></p>
            
            <p class="text-right">
                <button class="btn btn-secondary" type="button" @click="deleteSheet"><v-icon dark>mdi-delete</v-icon><!--{{$t('time_delete_sheet')}}--></button>
                <button class="btn btn-primary" type="button" @click="editSheet"><v-icon dark class="mr-2">mdi-pencil</v-icon>{{$t('time_edit_sheet')}}</button>
            </p>
            <div class="text-right">
                <button type="button" class="btn btn-text" @click="$router.go(-1)">{{$t('time_back')}}</button>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';
import sheetform from './../components/sheetForm.vue';
import dateformat from '~/components/dateformat.vue';
import currentlyRunning from './../components/currentlyRunning.vue';
import timedifference from './../components/timedifference.vue';

export default {
    name: 'sheet',
    components: {
        sheetform,
        dateformat,
        timedifference,
        currentlyRunning
    },
    data: () => ({
        sheet: null,
        editMode: null
    }),
    created: function () {
        this.getData();
    },
    computed:{
        ...mapGetters({
            projects: 'time/projects'
        }),
        project:function() {
            return this.$helper.getProject(this.sheet.projectId, this.projects);
        }
    },
    methods: {
        openModal : function(modalId) {
            $('#'+modalId).modal('show');
        },
        sameDay:function() {
            if(this.sheet && this.sheet.start && this.sheet.end) {
                if( this.sheet.start.getFullYear() !== this.sheet.end.getFullYear() ||
                    this.sheet.start.getMonth() !== this.sheet.end.getMonth() ||
                    this.sheet.start.getDate() !== this.sheet.end.getDate()) return false;
            }
            return true;
        },
        getData : async function() {
            let that = this;
            const {data} = await axios.get('./wp-json/tm_time/v1/sheet/'+this.$route.params.id);
            that.sheet = this.$helper.transformSheet(data);
        },
        deleteSheet : async function() {
            if(confirm( this.$t('time_sheet_confirm_delete') )) {
                const {data} = await axios.delete(
                    './wp-json/tm_time/v1/sheet/'+this.$route.params.id, { 
                        params: {
                            id:this.$route.params.id, 
                            force: true 
                        }
                    });

                // Redirect 
                this.$router.push({ name: 'time.sheets' });
            }
        },
        editSheet:function(shouldStopAndEdit) {
            if(this.sheet.end===null && shouldStopAndEdit===true) {
                this.$router.push({ name: 'time.editsheet', params: { id: this.sheet.id, stopSheet:true }});
            } else {
                this.$router.push({ name: 'time.editsheet', params: { id: this.sheet.id }});
            }
        }
    },
}
</script>

