<template>
    <v-card>
        <v-card-title>
            {{$t('time_create_new_project')}}
        </v-card-title>
        <v-card-text>
            <projectform v-if="project" :data="project"></projectform>
            <div class="text-right mt-2">
                <v-btn text @click="$router.go(-1)">{{$t('time_back')}}</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import projectform from './../components/projectForm.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        projectform : projectform
    },
    props:{
        createWithCustomerId: { //stored by the route
            type:Number,
            default:null
        },
    },
    data: () => ({
        project:undefined
    }),
    created: function () {
        let newProject = {
            title: "",
            notice: "",
            customer: null
        };
        if(this.createWithCustomerId!==null) {
            newProject.customer = this.$helper.getCustomer(this.createWithCustomerId, this.customers);
        }
        this.project = newProject;
    },
    computed:{
        ...mapGetters({
            customers: 'time/customers'
        }),
    },
    methods: {
    },
}
</script>