<template>
    <card :title="$t('reset_password')">
        <form @submit.prevent="send" @keydown="form.onKeydown($event)">
            <alert-success :form="form" :message="status"/>

            <!-- Email -->
            <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('email') }}</label>
                <div class="col-md-9">
                    <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" type="email" name="email">
                    <has-error :form="form" field="email"/>
                </div>
            </div>

            <!-- Submit Button -->
            <div class="text-right">
                <button :disabled="form.busy" class="btn btn-primary" type="submit">{{ $t('send_password_reset_link') }}</button>
            </div>
        </form>
    </card>
</template>

<script>
import Form from 'vform'

export default {

    data: () => ({
        status: '',
        form: new Form({
            email: ''
        })
    }),

    methods: {
        async send () {
            const { data } = await this.form.post('./api/password/email')

            this.status = data.status

            this.form.reset()
        }
    }
}
</script>
