<template>
    <span class="selectProject">
        <v-dialog v-model="modal" persistent max-width="400">
            <template v-slot:activator="{ on }">
                <v-btn class="secondary text-none projectButton" v-on="on">
                    <!--<v-btn class="secondary text-none projectButton" @click="openProjectSelectionModal">-->
                    <template v-if="project">
                        <span class="projectName" v-html="project.name"></span> <span class="customerName" v-html="project.customer.name"></span>
                    </template>
                    <template v-else>
                        {{$t('time_please_choose')}}
                    </template>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline">{{$t('time_select_project_headline')}}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        :label="$t('time_select_customer')"
                        :items="selectableCustomers"
                        item-text="name"
                        return-object
                        v-model="selectedCustomer"
                        >
                    </v-autocomplete>

                    <v-autocomplete
                        :label="$t('time_select_project')"
                        :items="selectableProjects"
                        item-text="name"
                        return-object
                        v-model="selectedProject"
                        @change="selectProject"
                        >
                        <template v-slot:item="data">
                            <template v-if="selectedCustomer!==null">
                                <v-list-item-content v-text="data.item.name"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.customer.name"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                    <template v-for="e in errors" >
                        <v-alert :key="e" dense type="error">{{e}}</v-alert>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="reset">{{$t('reset')}}</v-btn>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="confirm()">{{$t('ok')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
import i18n from '~/modules/i18n'
export default {
    components:{},
    props: {
        project : {
            required:true
        }
    },
    data: () => ({
        selectedProject:null,
        selectedCustomer:null,
        modal:false,
        errors: []
    }),
    mounted() {
        this.reset();
    },
    methods: {
        reset() {
            if(this.project!==null) {
                this.selectProject(this.project);
            } else {
                this.selectedProject = null;
                this.selectedCustomer = null;
            }
        },
        selectProject(p) {
            this.selectedProject = p;
            this.selectedCustomer = p.customer;
            if(this.modal!==false) {
                this.confirm();
            }
        },
        selectCustomer(c) {
            this.selectedCustomer = c;
            this.selectedProject = null;
        },
        isValid() {
            this.errors = [];
            var result = true;

            if(this.selectedProject === null) {
                this.errors.push(this.$t('time_alert_choose_a_project'));
            }
            return this.errors.length===0;
        },
        confirm() {
            if(this.isValid()) {
                this.modal = false;
                this.$emit('after',this.selectedProject);
            }
        }
    },
    computed:{
        ...mapGetters({
            projects: 'time/projects',
            customers: 'time/customers'
        }),
        selectableCustomers() {
            if(this.customers===null) return [];
            if(this.customers) {
                return this.customers.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                });
            }
        },
        selectableProjects() {
            let that = this;
            if(that.projects===null) return [];
            if(that.selectedCustomer===null) {
                return that.projects.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    });
            }
            return that.projects
                        .filter((item) => {
                            if(item.customer) return item.customer.id===that.selectedCustomer.id
                            return false;
                        })
                        .sort(function(a, b){
                            if(a.name < b.name) { return -1; }
                            if(a.name > b.name) { return 1; }
                            return 0;
                        });
        }
    },
}
</script>

