<template>
    <div ref="root" class="chart-root"></div>
</template>

<script>
import bb from 'billboard.js/dist/billboard.pkgd.min.js'
import { debounce, cloneDeep, defaultsDeep } from 'lodash'

export default {
    name: 'chart',
    props: {
        config: { type: Object, default: () => ({}) },
        data: { type: Object, default: () => ({}) },
    },
    data:() =>({
        chart:null,
    }),
    mounted () {
        const args = this.getArgs();
        this.chart = bb.generate({
            bindto: this.$refs.root,
            ...args
        })
        this.$emit('init', { args: args, chart: this.chart } );
    },
    beforeDestroy () {
        this.chart = this.chart.destroy();
    },
    watch: {
        data: {
            //handler: 'reload',
            handler: 'update',//needed for dougnut diagram to work
            deep: true
        }
    },
    methods: {
        getArgs () {
            const data = this.getData()
            const config = this.getConfig()
            return defaultsDeep({ data }, config)
        },
        getData () {
            return cloneDeep(this.data)
        },
        getConfig () {
            return cloneDeep(this.config)
        },
        update: debounce(function update () {
            const data = this.getData();
            this.chart.load(data);
            this.$emit('update', data)
        }, 250),
        reload: debounce(function reload () {
            this.$emit('reloading')
            this.chart.unload()
            this.$nextTick(() => {
                this.update()
            })
        }, 500)
    }
}
</script>