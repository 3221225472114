<template>
    <v-card class="dashboardLatestSheetsWrap">
        <v-card-title>Die {{sizeLast}} neuesten Zeitlogs</v-card-title>
        <v-card-text>
            <p class="text-center" v-if="sheets===null">
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </p>
            <sheetlist :sheets="latestSheets" @reload="reload"></sheetlist>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios';
import sheetlist from './../sheetlist.vue';
import { mapGetters } from 'vuex';
export default {
    components:{
        sheetlist
    },
    data: () => ({
        sizeLast: 10,
    }),
    computed: {
        ...mapGetters({
            sheets: 'time/latestSheets',
        }),
        latestSheets() {
            let result = [];
            result = this.sheets.filter(item => !!item.end);
            if(result.length > this.sizeLast) {
                result = result.slice( 0, this.sizeLast);
            }
            return result;
        }
    },
    methods: {
        reload() {
            this.$store.dispatch('time/fetchLatestSheets');// Fetch the customers
        },
    },
}
</script>