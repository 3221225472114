<template>
   
    <card :title="$t('register')">
        <form @submit.prevent="register" @keydown="form.onKeydown($event)">
            <!-- Name -->
            <div class="form-group row">
                <label class="col-md-4 col-form-label text-md-right">{{ $t('name') }}</label>
                <div class="col-md-8">
                    <input v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }" class="form-control" type="text" name="name">
                    <has-error :form="form" field="name"/>
                </div>
            </div>

            <!-- Email -->
            <div class="form-group row">
                <label class="col-md-4 col-form-label text-md-right">{{ $t('email') }}</label>
                <div class="col-md-8">
                    <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" type="email" name="email">
                    <has-error :form="form" field="email"/>
                </div>
            </div>

            <!-- Password -->
            <div class="form-group row">
                <label class="col-md-4 col-form-label text-md-right">{{ $t('password') }}</label>
                <div class="col-md-8">
                    <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" class="form-control" type="password" name="password">
                    <has-error :form="form" field="password"/>
                </div>
            </div>

            <!-- Password Confirmation -->
            <div class="form-group row">
                <label class="col-md-4 col-form-label text-md-right">{{ $t('confirm_password') }}</label>
                <div class="col-md-8">
                    <input v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" class="form-control" type="password" name="password_confirmation">
                    <has-error :form="form" field="password_confirmation"/>
                </div>
            </div>

            <div class="text-right">
                <!-- Submit Button -->
                <button :disabled="form.busy" class="btn btn-primary" type="submit">{{ $t('register') }}</button>
            </div>
        </form>
    </card>
</template>

<script>
import Form from 'vform'

export default {
    data: () => ({
        form: new Form({
            name: '',
            email: '',
            password: '',
            password_confirmation: ''
        })
    }),

    methods: {
        async register () {
            // Register the user.
            const { data } = await this.form.post('./api/register')

            // Log in the user.
            const { data: { token } } = await this.form.post('./api/login')

            // Save the token.
            this.$store.dispatch('auth/saveToken', { token })

            // Update the user.
            await this.$store.dispatch('auth/updateUser', { user: data })

            // Redirect home.
            this.$router.push({ name: 'home' })
        }
    }
}
</script>
