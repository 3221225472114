<template>
    <v-card class="dashboardWeekOverview my-3">
        <v-card-title class="pb-0">Die letzten 7 Tage</v-card-title>
        <v-card-text v-if="sheets!==null && sheets.length>0">
            <v-sparkline class="mt-5"
                :value="lastWeek.value"
                :gradient="['#dd0','#0a0']"
                :smooth="1"
                :padding="2"
                :gradient-direction="'bottom'"
                :fill="false"
                :type="'bar'"
                :auto-line-width="true"
                :label-size="8"
                >
            </v-sparkline>
            <v-row class="px-3 lastWeekDays">
                <v-col v-for="(c,index) in lastWeek.labels" :key="index">
                    <div class="label">{{c}}</div>
                    <div class="unit">{{$helper.getHumanReadableByDiffSeconds(lastWeek.value[index])}}</div>
                </v-col>
            </v-row>
            <v-row class="pt-3">
                <v-col cols="12" lg="6" class="py-0">
                    Gesamt: <strong>{{$helper.getHumanReadableByDiffSeconds(durchschnitt)}}</strong>
                </v-col>
                <v-col cols="12" lg="6" class="py-0 text-left text-lg-right">
                    5 Tage Durchschnitt: <strong>{{$helper.getHumanReadableByDiffSeconds(durchschnitt / 5)}}</strong>
                </v-col>
            </v-row>
        </v-card-text>
        
        <v-card-text v-if="sheets!==null && sheets.length===0">
            <div class="text-center my-10 py-10">In der letzten Woche wurden keine Einträge angelegt.</div>
        </v-card-text>

        <v-card-title v-if="lastCustomersResult.value.length>0" class="mt-5 pb-1">Kunden mit dem höchsten Aufwand</v-card-title>
        <v-card-text v-if="lastCustomersResult.value.length>1">
            <v-sparkline
                :value="lastCustomersResult.value"
                :gradient="['#06a','#6bc1ff']"
                :smooth="1"
                :padding="2"
                :gradient-direction="'top'"
                :fill="false"
                :type="'bar'"
                :auto-line-width="true"
                :show-labels="false"
                :label-size="8"
                >
            </v-sparkline>
            <v-row class="px-3 lastCustomersLabels">
                <v-col v-for="(value,index) in lastCustomersResult.labels" :key="index" :style="{ width: (lastCustomersResult.length/100)+'%' }">
                    <router-link class="label d-block" :to="{ name: 'time.customer', params: { id: lastCustomers[index].customerId }}">{{lastCustomers[index].customerName}}</router-link>
                    <div class="unit">{{$helper.getHumanReadableByDiffSeconds( lastCustomers[index].sum )}}</div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text v-if="lastCustomersResult.value.length===1">
            <div class="lastCustomersLabels text-center single" style="background:linear-gradient(#06a,#6bc1ff);">
                <router-link class="label d-block" :to="{ name: 'time.customer', params: { id: lastCustomers[0].customerId }}">{{lastCustomers[0].customerName}}</router-link>
                <div class="unit">{{$helper.getHumanReadableByDiffSeconds( lastCustomers[0].sum )}}</div>
            </div>
        </v-card-text>

        <v-card-title v-if="lastProjectsResult.value.length>0" class="mt-5 pb-1">Projekte mit dem höchsten Aufwand</v-card-title>
        <v-card-text v-if="lastProjectsResult.value.length>1">
            <v-sparkline
                :value="lastProjectsResult.value"
                :gradient="['#800080','#af5aaf']"
                :smooth="1"
                :padding="2"
                :gradient-direction="'top'"
                :fill="false"
                :type="'bar'"
                :auto-line-width="true"
                :show-labels="false"
                :label-size="8"
                >
            </v-sparkline>
            <v-row class="px-3 lastProjectsLabels">
                <v-col v-for="(value,index) in lastProjectsResult.labels" :key="index" :style="{ width: (lastProjectsResult.length/100)+'%' }">
                    <router-link class="label project" :to="{ name: 'time.project', params: { id: lastProjects[index].projectId }}">{{lastProjects[index].projectName}}</router-link>
                    <router-link class="label customer" :to="{ name: 'time.customer', params: { id: lastProjects[index].customerId }}">{{lastProjects[index].customerName}}</router-link>
                    <div class="unit">{{$helper.getHumanReadableByDiffSeconds( lastProjects[index].sum )}}</div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text v-if="lastProjectsResult.value.length===1">
            <div class="lastProjectsLabels text-center single" style="background:linear-gradient(#800080, #af5aaf);">
                <router-link class="label project" :to="{ name: 'time.project', params: { id: lastProjects[0].projectId }}">{{lastProjects[0].projectName}}</router-link>
                <router-link class="label customer" :to="{ name: 'time.customer', params: { id: lastProjects[0].customerId }}">{{lastProjects[0].customerName}}</router-link>
                <div class="unit">{{$helper.getHumanReadableByDiffSeconds( lastProjects[0].sum )}}</div>
            </div>
        </v-card-text>
        
    </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { startOfDay } from 'date-fns';
export default {
    data: () => ({
        sheets: null,
    }),
    computed:{
        ...mapGetters({
            projects: 'time/projects'
        }),
        durchschnitt() {
            let sum = 0;
            console.log('lastWeek',this.lastWeek);
            this.lastWeek.value.forEach((item) => {
                sum+=item;
            });
            return sum;
        },
        lastProjects() {
            if(this.sheets===null) return [];

            let projectsObject = {};
            let index = -1;
            let currentDay = null;
            this.sheets.forEach(element => {
                let key = element.time_meta.project;
                if(!projectsObject[key]) {
                    let proj = this.$helper.getProject( key, this.projects );
                    if(proj===null) {
                        this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
                    } else {
                        projectsObject[key] = {
                            sum : 0,
                            projectName : proj.name,
                            projectId : proj.id,
                            customerId : proj.customerId,
                            customerName : proj.customer.name
                        };
                    }
                }
                projectsObject[key].sum+=element.diff;
            });

            let projects = [];
            for(let key in projectsObject) {
                projects.push( projectsObject[key] );
            }
            projects.sort((a,b) => b.sum-a.sum);
            return projects
        },
        lastProjectsResult() {
            let result = { value:[], labels:[] };
            
            this.lastProjects.forEach(elem => {
                result.value.push( elem.sum );
                result.labels.push( elem.projectName );
            });
            
            result.value = result.value.slice(0,3);
            result.labels = result.labels.slice(0,3);
            return result;
        },
        lastCustomers() {
            if(this.sheets===null) return [];

            let customersObjects = {};
            let index = -1;
            let currentDay = null;
            this.sheets.forEach(element => {
                let proj = this.$helper.getProject( element.time_meta.project, this.projects );
                if(proj===null) {
                    this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
                } else {
                    let key = proj.customerId;
                    if(!customersObjects[key]) {
                        customersObjects[key] = {
                            sum : 0,
                            customerId : proj.customer.id,
                            customerName : proj.customer.name
                        };
                    }
                    customersObjects[key].sum+=element.diff;
                }
            });

            let customers = [];
            for(let key in customersObjects) {
                customers.push( customersObjects[key] );
            }
            customers.sort((a,b) => b.sum-a.sum);
            return customers
        },
        lastCustomersResult() {
            let result = { value:[], labels:[] };
            
            this.lastCustomers.forEach(elem => {
                result.value.push( elem.sum );
                result.labels.push( elem.customerName );
            });
            
            result.value = result.value.slice(0,3);
            result.labels = result.labels.slice(0,3);
            return result;
        },
        lastWeek() {
            let result = { value:[], labels:[] };
            if(this.sheets===null) return result;


            let daysObject = {};

            //fill result with zero values
            let current = new Date();
            for(let i = 0; i<7; i++) {
                let key = this.$helper.format(current, 'dd.MM.yyyy');
                daysObject[key] = {
                    sum : 0,
                    date: current,
                    dateTime: current.getTime(),
                    key : key,
                    weekDay : this.$helper.format(current, 'EEEEEE')
                };
                current.setDate(current.getDate() - 1);
            }

            this.sheets.forEach(element => {
                let key = element.startDay;
                daysObject[key].sum+= element.diff;
            });

            let days = [];
            for(let key in daysObject) {
                days.push( daysObject[key] );
            }
            days.sort((a,b) => b.dateTime-a.dateTime);


            days.forEach((elem,index) => {
                result.value.push(elem.sum);
                result.labels.push(elem.weekDay);
            });

            return result;
            /*
            return {
                value:[2, 5, 9, 5, 10, 3, 5],
                labels: ['MO','DI','MI','DO','FR','SA','SO'],
            }*/
        }
    },
    mounted() {
        this.loadData();
    },
    methods:{
        loadData() {
            let that = this;
            var lastWeek = startOfDay(new Date( (new Date()).getTime() - (6 * 24 * 60 * 60 * 1000)) );
            
            axios.get('./wp-json/tm_time/v1/analysis', {
                    params: {
                        start: this.$helper.format(lastWeek, 'dd.MM.yyyy'),
                        end: 'now'
                    }
                })
                .then(response => {
                    that.sheets = response.data.sheets;
                })
                .catch(response => {})
                .finally({})
        },
        enhanceLabel(index) {
            let diff = this.lastWeek.value[index];
            var hours = Math.floor(diff / 60 / 60);
            var minutes = Math.floor(diff / 60);
            if(hours>0) {
                minutes = minutes - (hours * 60);
            }

            return hours+':'+('0'+minutes).slice(-2);
        }
    }
}
</script>