<template>
    <v-card class="dashboardCurrentlyRunningWrap">
        <v-card-title>Aktive Zeitlogs</v-card-title>
        <v-card-text >
            <sheetlist :sheets="latestSheets"></sheetlist>
            <div v-if="latestSheets.length===0" class="text-center py-5">
                Aktuell gibt es keine laufenden Zeitlogs.
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios';
import sheetlist from './../sheetlist.vue';
import { mapGetters } from 'vuex';
export default {
    components:{
        sheetlist
    },
    data: () => ({
        sizeLast: 10,
    }),
    computed: {
        ...mapGetters({
            sheets: 'time/latestSheets',
        }),
        latestSheets() {
            return this.sheets.filter(item => !item.end);
        }
    },
}
</script>