<template>
    <card :title="$t('userroles')">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>{{ $t('name') }}</th>
                    <th>{{ $t('email') }}</th>
                    <th>{{ $t('role') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="u in users.data">
                    <td>{{u.name}}</td>
                    <td>{{u.email}}</td>
                    <td>
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="u.busy">
                                {{u.role}}
                            </button>
                            <div class="dropdown-menu">
                                <button class="dropdown-item" type="button" v-for="r in roles" v-on:click="saveRole(u,r)">{{r}}</button>
                            </div>
                        </div>
                    
                    </td>
                </tr>
            </tbody>
        </table>
    </card>
</template>

<script>
import axios from 'axios';
export default {
    middleware: 'auth',

    created: function() {
        this.getUsers();
        this.getRoles();
    },
    data: function() {
        return {
            users:{
                data:[]
            },
            roles: []
        }
    },
    methods: {
        getUsers: async function() {
            const result = await axios.get('./api/userlist');
            this.users = result.data;
        },
        getRoles: async function() {
            const result = await axios.get('./api/userroles');
            this.roles = result.data;
        },
        saveRole: async function(user,role) {
            user.busy = true;
            const result = await axios.post('./api/userroles', { id:user.id, role:role } );
            user.role = result.data.user.role;
            user.busy = false;
        }
    },
    computed: {
    }
}
</script>
