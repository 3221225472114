<template>
    <v-list v-if="lastSheetTitles" dense>
        <v-subheader>{{$t('time_log_title_menu_label')}}</v-subheader>
        <v-list-item v-for="(title,$index) in lastSheetTitles" :key="$index" @click="selectTitle(title)">
            <v-list-item-title>{{title}}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <template v-if="defaultSheetTitles && defaultSheetTitles.length>0">
            <v-list-item v-for="(title,$index) in defaultSheetTitles" :key="$index+'default'" @click="selectTitle(title)">
                <v-list-item-title>{{title}}</v-list-item-title>
            </v-list-item>
        </template>
        <template v-else>
            <v-list-item @click="openSettings">
                <v-list-item-content>
                    <v-list-item-title>Bisher keine Standard-Titel vergeben</v-list-item-title>
                    <v-list-item-subtitle>jetzt pflegen</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        sheets: {
            required:true
        }
    },
    
    computed:{
        ...mapGetters({
            defaultSheetTitles: 'time/defaultSheetTitles'
        }),
        lastSheetTitles() {
            if(this.sheets) {
                let sheetNames = [];
                for(let i = 0; i < this.sheets.length; i++) {
                    if(sheetNames.indexOf( this.sheets[i].title ) === -1) {
                        sheetNames.push( this.sheets[i].title );
                    }
                    if(sheetNames.length>=8) {
                        break;
                    }
                }
                return sheetNames;
            }
        },
    },
    methods: {
        openSettings() {
            document.timesheetmanager.openSettings();
        },
        selectTitle(title) {
            this.$emit('useTitle',title);
        }
    }

}
</script>