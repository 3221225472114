<template>
    <card :title="$t('your_password')">
        <form @submit.prevent="update" @keydown="form.onKeydown($event)">
            <alert-success :form="form" :message="$t('password_updated')"/>

            <!-- Password -->
            <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('new_password') }}</label>
                <div class="col-md-7">
                    <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" class="form-control" type="password" name="password">
                    <has-error :form="form" field="password"/>
                </div>
            </div>

            <!-- Password Confirmation -->
            <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('confirm_password') }}</label>
                <div class="col-md-7">
                    <input v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" class="form-control" type="password" name="password_confirmation">
                    <has-error :form="form" field="password_confirmation"/>
                </div>
            </div>

            <!-- Submit Button -->
            <div class="form-group row">
                <div class="col-md-9 ml-md-auto">
                    <button :loading="form.busy" type="submit" class="btn btn-primary">{{ $t('update') }}</button>
                </div>
            </div>
        </form>
    </card>
</template>

<script>
import Form from 'vform'

export default {
    scrollToTop: false,

    metaInfo () {
        return { title: this.$t('settings') }
    },

    data: () => ({
        form: new Form({
            password: '',
            password_confirmation: ''
        })
    }),

    methods: {
        async update () {
            await this.form.post('./api/settings/password')

            this.form.reset()
        }
    }
}
</script>
