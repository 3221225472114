<!--
HOW TO USE
add MessageSnackBar once anywhere inside your vuetify app.
On a place where it always get loaded, i.e. before the closing "v-app"
<MessageSnackBar entrypoint="my_entrypoint"></MessageSnackBar>

Now you can use it anywhere in your code:
### promise based
    document.my_entrypoint.openSnackbar({ message:"Thats the text" }).then(() => {
      console.log('Alert closed');
    });

OR
### syncron

    async test() {
      await document.my_entrypoint.openSnackbar({ message:"thats the text" });
      console.log('Alert closed');
    }

### Parameters
- message
- btn_accept (optional)
- type (optional) a class variant added to the dialog
- timeout (optional - default -1) time in ms to keep the snackbar open
-->

<template>
  <v-snackbar v-model="dialog" :timeout="timeout" :content-class="dialogClass"
    vertical bottom right>
    <span v-html="message"></span>
    <template v-slot:action="{ attrs }">
      <v-btn color="primary" depressed v-bind="attrs" @click="accept">{{btn_accept}}</v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  props:{
    entrypoint: {//the EntryPoint defines where the functions should be available
      required:true,
      type:String
    }
  },
  data:() => ({
    dialog:false,
    message:"",
    btn_accept: "",
    type:"",
    timeout:-1,
  }),
  computed: {
    dialogClass() {
      return 'messageSnackbarWrap messageSnackbarTheme-'+this.type;
    }
  },
  mounted() {
    this.reset();
    document.addEventListener(this.entrypoint+'_open_message_snackbar', this.handleOpenEvent, { once:false /* handle listener only once*/ });
    if(document[this.entrypoint]===undefined) document[this.entrypoint] = {};
    document[this.entrypoint].openSnackbar = this.publicFunction;
  },
  beforeDestroy() {
    document.removeEventListener(this.entrypoint+'_open_message_snackbar', this.handleCloseEvent);
    document[this.entrypoint].openSnackbar = undefined;
  },
  methods:{
    reset() {
      this.dialog = false;
      this.message = "";
      this.btn_accept = "OK";
      this.type = "default";
      this.timeout = -1;
    },
    handleOpenEvent(event) {
      this.dialog = true;
      this.message = event.detail.message;
      if(event.detail.btn_accept) this.btn_accept = event.detail.btn_accept;
      if(event.detail.type) this.type = event.detail.type;
      if(event.detail.timeout) this.timeout = event.detail.timeout;
    },
    accept() {
      var event = new CustomEvent(this.entrypoint+'_message_snackbar_close', { detail: { 'type': 'accept' }});
      document.dispatchEvent(event);
      this.reset();
    },

    /**
     * This function will be available via document.<your-entrypoint>.openSnackbar
     */
    publicFunction(data) {
      //send open event to MessageSnackbar-component
      document.dispatchEvent(
        new CustomEvent(this.entrypoint+'_open_message_snackbar', { detail: data })
      );

      return new Promise((resolve) => {//return promise so the caller can wait for it
        //wait for close event from MessageSnackbar-component
        document.addEventListener(this.entrypoint+'_message_snackbar_close', () => {
          resolve();
        }, { once: true /* handle listener only once*/ });

      });
    }
  }
}
</script>
