<template>
    <v-card class="singleCustomer" v-if="customer" >
        <v-card-text>
            <h1 class="headline d-flex">
                <v-icon style="vertical-align: text-bottom;" large>mdi-account</v-icon>
                <span>{{ customer.name }}</span>
                <v-spacer></v-spacer>
                <v-btn icon depressed @click="doEdit" :aria-label="$t('time_edit_customer')" :title="$t('time_edit_customer')">
                    <v-icon dark small>mdi-pencil</v-icon>
                </v-btn>
            </h1>
            <div class="subtitle ml-9">
                {{$t('time_customer')}}
            </div>

            <v-tabs v-model="activeTab" centered v-if="customer.projects" background-color="grey lighten-4" show-arrows grow>
                <v-tab>
                    {{$t('time_customer_tab_last')}}
                </v-tab>
                <v-tab-item>
                    <div 
                        v-if="!projectsOrderedByUsage" 
                        class="waitingForLatestProjects text-center pt-2 mb-0 pb-2"
                        :style="calculateLatestProjectsHeight"
                    >
                        <v-progress-circular indeterminate color="primary" ></v-progress-circular>
                    </div>
                    
                    <div v-else>
                        <ul class="list-unstyled px-0 py-2 mb-0">
                            <template v-for="(p,$index) in projectsOrderedByUsage">
                                <li v-if="$index < 5 || showAllOrderedByUsageProjects" :key="p.id">
                                    <router-link :to="{ name: 'time.project', params: { id: p.id }}">{{p.name}}</router-link>
                                </li>
                            </template>
                        </ul>
                        <v-btn small v-if="customer.projects.length>5 && !showAllOrderedByUsageProjects" @click="showAllOrderedByUsageProjects=true"><v-icon small>mdi-dots-vertical</v-icon></v-btn>
                        <v-btn small v-if="customer.projects.length>5 && showAllOrderedByUsageProjects" @click="showAllOrderedByUsageProjects=false"><v-icon small>mdi-minus</v-icon></v-btn>
                    </div>
                    
                </v-tab-item>

                <v-tab>
                    {{$t('time_customer_tab_by_name')}}
                    <span>({{projectsOrderedByName.length}})</span>
                </v-tab>
                <v-tab-item class="projectByNameTab">
                    <ul v-if="customer.projects" class="list-unstyled px-0 py-2 mb-0">
                        <template v-for="(p,$index) in projectsOrderedByName">
                            <li v-if="$index < 5 || showAllOrderedProjects" :key="p.id">
                                <router-link :to="{ name: 'time.project', params: { id: p.id }}">{{p.name}}</router-link>
                            </li>
                        </template>
                    </ul>
                    <v-btn small v-if="customer.projects.length>5 && !showAllOrderedProjects" @click="showAllOrderedProjects=true"><v-icon small>mdi-dots-vertical</v-icon></v-btn>
                    <v-btn small v-if="customer.projects.length>5 && showAllOrderedProjects" @click="showAllOrderedProjects=false"><v-icon small>mdi-minus</v-icon></v-btn>
                </v-tab-item>

                <v-tab>
                    {{$t('time_customer_tab_by_creation')}}
                </v-tab>
                <v-tab-item>
                    <ul v-if="customer.projects" class="list-unstyled px-0 py-2 mb-0">
                        <template v-for="(p,$index) in customer.projects">
                            <li v-if="$index < 5 || showAllUnorderedProjects" :key="p.id">
                                <router-link :to="{ name: 'time.project', params: { id: p.id }}">{{p.name}}</router-link>
                            </li>
                        </template>
                    </ul>
                    <v-btn small v-if="customer.projects.length>5 && !showAllUnorderedProjects" @click="showAllUnorderedProjects=true"><v-icon small>mdi-dots-vertical</v-icon></v-btn>
                    <v-btn small v-if="customer.projects.length>5 && showAllUnorderedProjects" @click="showAllUnorderedProjects=false"><v-icon small>mdi-minus</v-icon></v-btn>
                </v-tab-item>

                <v-tab class="searchProjectAutocomplete grey lighten-3 pa-0">
                    <v-autocomplete
                        v-model="projectFilter"
                        :items="customer.projects"
                        item-text="name"
                        :return-object="true"
                        :label="$t('time_search_for_project')"
                        prepend-icon="mdi-magnify"
                        @change="navigateToProject"
                        solo
                        flat
                    >
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-title>
                                    <em>{{$t('time_search_for_project_no_match')}}</em>
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-tab>
                <v-tab-item>
                </v-tab-item>
            </v-tabs>
            <v-container fluid>
                <v-row>
                    <v-btn @click="$router.push({ name: 'time.customeranalysis', params: { id: customer.id } })" class="success my-1" data-cy="customerAnalysis"><v-icon dark small class="mr-1">mdi-chart-bar-stacked</v-icon>{{$t('time_customer_analysis')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push({ name: 'time.createproject', params: { createWithCustomerId: customer.id } })" class="success mr-1 my-1" data-cy="addProject"><v-icon dark small class="mr-1">mdi-plus</v-icon>{{$t('time_add_project')}}</v-btn>
                </v-row>
            </v-container>
            <p class="text-center" v-if="sheets===null">
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </p>
            <sheetlist v-if="sheets!==null" :sheets="sheets" @reload="reload"></sheetlist>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios'
import sheetlist from './../components/sheetlist.vue';
import customer_editVue from './customer_edit.vue';

export default {
    name: 'customer',
    components: {
        sheetlist:sheetlist
    },
    data: () => ({
        sheets : null,
        activeTab:0,
        projectFilter:null,
        showAllOrderedProjects:false,
        showAllOrderedByUsageProjects:false,
        showAllUnorderedProjects:false,
    }),
    created: function () {
        this.getSheetData();
    },
    computed:{
        calculateLatestProjectsHeight() {
            if(this.customer && this.customer.projects) {
                let count = this.customer.projects.length;
                let height = count * 2;
                if(count>5) {
                    height = 5 * 1.82;
                    height+= 1.5;
                }
                return { 'min-height': height+'em' };
            }
        },
        customer() {
            return this.$store.getters['time/getCustomerById'](this.$route.params.id);
        },
        projectsOrderedByUsage() {
            if(this.sheets && this.customer && this.customer.projects) {
                let projectIds = [];
                let projectsResult = [];
                let that = this;
                for(let i = 0; i < this.sheets.length; i++) {
                    let sheet = this.sheets[i];
                    if(sheet.projectId && projectIds.indexOf(sheet.projectId)===-1) {
                        projectIds.push(sheet.projectId);
                        projectsResult.push( this.$helper.getProject(sheet.projectId, this.customer.projects));
                    }
                }

                //filter projects by projectIds
                return projectsResult;
            }
        },
        projectsOrderedByName() {
            if(this.customer && this.customer.projects) {
                let projectsArray = this.$helper.getArrayFromObject(this.customer.projects);
                if(projectsArray) {
                    return projectsArray.sort(function(a, b){
                        let x = a.name.toLowerCase();
                        let y = b.name.toLowerCase();
                        if(x < y) { return -1; }
                        if(x > y) { return 1; }
                        return 0;
                    });
                }
            }
        }
    },
    methods: {
        navigateToProject() {
            if(this.projectFilter) {
                this.$router.push({ name: 'time.project', params:{ id : this.projectFilter.id } });
            }
        },
        reload() {
            this.getSheetData()
        },
        getSheetData : async function() {
            let that = this;
            const { data } = await axios.get('./wp-json/tm_time/v1/sheetlist', { params: { customer : this.$route.params.id } });
            this.sheets = this.$helper.transformSheets(data);
        },
        doEdit:function() {
            // Redirect 
            this.$router.push({ name: 'time.editcustomer', params: { id:this.$route.params.id } });
        },
    },
}
</script>