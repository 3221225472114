<template>
	<div class="monthTable">
		<p>
			<v-btn small color="primary" class="copyToClipboard d-print-none" @click="copyToClipboard( createExcelVersion() )">{{$t('time_customer_analysis_to_cache')}}</v-btn>
			<v-btn small color="danger" class="projectFilterBtn d-print-none" v-if="filtered" @click="filterByProject()">{{$t('time_customer_analysis_reset_filtering')}}</v-btn>
			<router-link v-if="filterByProjectId" :to="{ name: 'time.projectanalysis', params: { id: filterByProjectId } }" class="d-print-none btn btn-sm btn-success"><v-icon dark small class="mr-1">assessment</v-icon>{{$t('time_project_analysis')}}</router-link>
		</p>
		<p>
			{{$t('time_customer_analysis_duration')}} <small>{{$t('time_customer_analysis_duration_time')}} </small>: {{getDurationString(diffSum)}}<br />
			{{$t('time_customer_analysis_duration')}} <small>{{$t('time_customer_analysis_duration_dezimal')}} </small>: {{getDezimalHourString(diffSum,2)}}<br />
			{{$t('time_customer_analysis_duration')}} <small>{{$t('time_customer_analysis_duration_persondays')}} </small>: {{getDezimalPersonDaysString(diffSum,2)}}
			
		</p>
		

		<div v-if="sheets.length>200 && !confirmManyEntries" class="warningManyEntries">
			{{$t('time_analysis_warning_many_entries', [sheets.length])}}<br />
			<v-btn class="mt-3" @click="confirmManyEntries=true">{{$t('time_analysis_warning_many_entries_btn')}}</v-btn>
		</div>
		<div v-else>
			<v-data-table
				:headers="headers"
				:items="sheets"
				:footer-props="{
					itemsPerPageOptions: [
						{ text: 'alle', value:-1 }
					] 
				}"
				hide-default-footer
			>
			
				<template v-slot:body="{ items }">
					<tbody>
						<tr v-for="(item,$index) in items" :key="$index">
							<td class="text-left">
								<template v-if="isDefaultSorting">
									<template v-if="!sheets[$index-1] || (sheets[$index-1] && sheets[$index-1]['startDay']!==sheets[$index]['startDay'])">
										{{item.startDay}}
									</template>
								</template>
								<template v-else>
									{{item.startDay}}
								</template>
							</td>
							<td class="text-left">
								{{item.startTime}}
							</td>
							<td class="text-left">
								{{item.endTime}}
							</td>
							<td class="text-left">
								{{item.break}}
							</td>
							<td class="text-left">
								{{item.diffStr}}
							</td>
							<td class="text-left">
								<a @click="filterByProject(item.projectId)">
									{{ $helper.decode(item.projectName) }}
								</a>
							</td>
							<td class="text-left">
								{{ $helper.decode(item.name) }}
							</td>
							<td class="justify-center d-print-none">
								<i aria-hidden="true" class="v-icon mr-2 v-icon--link mdi mdi-pencil theme--light" style="font-size: 16px;" @click="editItem(item)"></i>
							</td>
						</tr>
					</tbody>
				</template>
			</v-data-table>
		</div>

		<v-btn class="projectFilterBtn" v-if="filtered" @click="filterByProject()">{{$t('time_customer_analysis_reset_filtering')}}</v-btn>

<!--
		<div v-if="sheets.length<=200">
			<div class="chartWrap" v-if="chartData">
				<chart :config="chartConfig" :data="chartData"></chart>
			</div>
		</div>
		-->
	</div>
</template>

<script>
//import chart from './../c3Chart.vue';
export default {
	components: { },
	props: {
		sheets:{ required:true, type:Array }
	},
	data: function () {
		return {
			headers: [
				{ text: 'Datum', value : 'startDay', align:'left' },
				{ text: 'Start', value : 'startTime', sortable:false, align:'left' },
				{ text: 'Stop', value : 'endTime', sortable:false, align:'left' },
				{ text: 'Pause', value : 'break', sortable:false, align:'left' },
				{ text: 'Dauer', value : 'diff', align:'left' },
				{ text: 'Projekt', value : 'projectName', align:'left' },
				{ text: 'Beschreibung', value : 'name', align:'left' },
				{ text: '', sortable:false, align:'left', class:"d-print-none" },
			],
			filtered: false,
			filterByProjectId:null,
			confirmManyEntries:false,


			chartConfig:{
				axis: {
					x: {
						type: 'category', // this needed to load string x value
						show: false,
					},
					y: {
						label: 'Zeit in h',
					}
				},
				grid: {
					y: {
						show: true
					}
				},
				legend: {
					show: false
				},
				tooltip: {
					grouped: false
				}
			},
			chartData:null,
		}
	},
	mounted:function() {
		//this.prepareChart();
	},
	computed: {
		tableData() {
			return this.sheets;
		},
		isDefaultSorting() {
			if(this.$refs['monthtable'] && this.$refs['monthtable'].defaultPagination) {
				var base = this.$refs['monthtable'].defaultPagination;
				if( (base.sortBy===null && base.descending===null) || (base.sortBy==="startDay" && base.descending===false)) {
					return true;
				}
				return false;
			} else {
				return true;
			}
		},
		diffSum : function() {
			let result = 0;
			this.sheets.forEach(function(obj) {
				result+=obj.diff;
			});
			return result;
		},
		filteredSheets() {
			return 
		}
	},
	methods: {
		filterByProject(projectId) {
			
			this.$emit('filterByProject',projectId)
			/*/
			if(!projectId) {
				this.sheets = this.sheets;
				this.filtered = false;
				this.filterByProjectId = null;
			} else {
				this.sheets = this.sheets.filter(time => time.projectId == projectId);
				this.filtered = true;
				this.filterByProjectId = projectId;
			}
			//*/
		},
			/*
		prepareChart() {
			let data = this.sheets;
			let label = ['x'];
			let result = ['Tage'];
			
			//helper function to get an day object
			function getDay(e) {
				let d = new Date(parseInt(e.time_meta.start)*1000);
				d.setHours(0);
				d.setMinutes(0);
				d.setSeconds(0);
				d.setMilliseconds(0);
				return d;
			}

			let previousDay_obj = null;
			data.forEach(elem=>{
				let day = getDay(elem);
				
				while(previousDay_obj!==null && previousDay_obj.getTime()!=day.getTime()) {
					//add new day
					previousDay_obj.setDate( previousDay_obj.getDate() + 1 );
					
					let dayStr = ('0'+previousDay_obj.getDate()).slice(-2)+'.'+('0'+(previousDay_obj.getMonth()+1)).slice(-2)+'.'+('0'+previousDay_obj.getFullYear()).slice(-4);
					if(dayStr!=elem.startDay) {
						label.push( dayStr );
						result.push(0);
					}
				}
				previousDay_obj = day;


				label.push(elem.startDay);
				result.push( (elem.diff / 60 / 60).toFixed(2) );
			});
			this.chartData = {
				x : 'x',
				columns: [ label, result ],
				type: 'bar'
			};
		},
			//*/
		getDurationString(diff) {
			var hours = Math.floor(diff / 60 / 60);
			var minutes = Math.floor(diff / 60);
			var result = '';
			result+= hours + ':';
			minutes = minutes - (hours * 60);
			result+= new String(minutes).padStart(2,'0');
			return result;
		},
		getDezimalHourString(seconds,fixed){
			if(typeof fixed !== 'number') fixed = 3;
			return (seconds / 60 / 60).toFixed(fixed).replace(",","").replace(".",",");
		},
		getDezimalPersonDaysString(seconds,fixed){
			if(typeof fixed !== 'number') fixed = 3;
			return (seconds / 60 / 60 / 8).toFixed(fixed).replace(",","").replace(".",",");
		},
		createExcelVersion() {
			let result = "Startdatum\tStartzeit\tEnddatum\tEndzeit\tPause\tDauer\tProjekt\tBeschreibung\n";
			this.sheets.forEach(function(obj) {
				result+= obj.startDay+"\t"+obj.startTime+"\t"+obj.endDay+"\t"+obj.endTime+"\t"+obj.break+"\t"+obj.diffStr+"\t"+obj.projectName+"\t"+obj.name+"\n";
			});
			
			let sum = this.diffSum;
			result+= "\t\t\tDauer: \t"+this.getDurationString(sum)+"\t\t\t\n";
			result+= "\t\t\tDauer (dezimal):\t"+this.getDezimalHourString(sum,2)+"\t\t\t\n";
			result+= "\t\t\tDauer (Personentage):\t"+this.getDezimalPersonDaysString(sum,2)+"\t\t\t\n";
			return result;
		},
		
		copyToClipboard(text) {
			const el = document.createElement('textarea');
			el.value = text;
			el.setAttribute('readonly', '');
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			alert('Daten dieser Ansicht wurden als CSV in die Zwischenablage gelegt.')
		},
		editItem(item) {
			this.$router.push({ name:"time.editsheet", params: { id:item.id }});
		}
	}
}

</script>