import Cookies from 'js-cookie'
const { locale, locales } = window.timesheetapp


/** mutationTypes ************************************************************************/
export const mutationTypes = {
    SET_LOCALE : 'SET_LOCALE'
};

/** store module **************************************************************************/
export const storeModule = {
    namespaced: true,
    state: {
        locale: Cookies.get('locale') || locale,
        locales: locales
    },
    getters : {
        locale: state => state.locale,
        locales: state => state.locales
    },
    mutations : {
        [mutationTypes.SET_LOCALE](state, { locale }) {
            state.locale = locale
        }
    },
    actions : {
        setLocale({ commit }, { locale }) {
            commit(mutationTypes.SET_LOCALE, { locale })

            Cookies.set('locale', locale, { expires: 365 })
        }
    }
};
