<template>
    <section id="tsNavigation">
        <v-app-bar color="blue darken-2" dark app :clipped-left="user!==null" dense>

            <v-app-bar-nav-icon v-if="isMobile && user" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <router-link :to="{ name: user ? 'home' : 'welcome' }" custom v-slot="{ navigate }">
                <v-toolbar-title @click="navigate" role="link">{{ appName }}</v-toolbar-title>
            </router-link>
        
            <v-btn v-if="!isOnline" x-small  class="mx-2 warning" >OFFLINE</v-btn>

            <div class="flex-grow-1"></div>

            <template v-if="user">
                <usersettings></usersettings>
            </template>
            <template v-else>
                <router-link :to="{ name:'login' }" class="primary">{{$t('login')}}</router-link>
                <router-link :to="{ name: 'register' }" class="primary">{{ $t('register') }}</router-link>
            </template>
        </v-app-bar>

        <v-navigation-drawer v-if="user!==null" class="sidebarNavigation grey darken-3" app clipped dark 
            v-model="drawer"
            :mobile-breakpoint="mobileBreakpoint">
            <v-list dense>
                <v-list-item class="mt-4 grey--text text--darken-1" @click.prevent="performLink({ name: 'time.dashboard' })">
                    <v-list-item-icon class="mr-2"><v-icon>mdi-view-grid</v-icon></v-list-item-icon>
                    <v-list-item-content>Dashboard</v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-icon class="mr-2 grey--text text--darken-1">mdi-account</v-icon> 
                    <v-list-item-content class="pb-0 grey--text text--darken-1">Kunden</v-list-item-content>
                </v-list-item>
                <v-list>
                    <v-list-item v-for="c in customersOrderedByName" :key="c.id" 
                        :class="{'grey darken-1' : isCurrentRoute({ name: 'time.customer', params: { id: c.id }})}"
                        @click.prevent="performLink({ name: 'time.customer', params: { id: c.id }})">
                        <v-list-item-icon class="mr-2"><v-icon>mdi-chevron-right</v-icon></v-list-item-icon>
                        <v-list-item-content>{{c.name}}</v-list-item-content>
                    </v-list-item>
                    <v-list-item :class="{'grey darken-1' : isCurrentRoute({ name: 'time.createcustomer' })}" @click.prevent="performLink({ name: 'time.createcustomer' })">
                        <v-list-item-icon class="mr-2"><v-icon>mdi-plus-circle-outline</v-icon></v-list-item-icon>
                        <v-list-item-content>{{$t('time_add_customer')}}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-list>
            
            <template v-slot:append>
                <v-btn small block text @click="doAppReload"><version></version> <span class="mx-2"><v-icon small>mdi-autorenew</v-icon></span></v-btn>
            </template>
        </v-navigation-drawer>
    </section>
</template>

<script>
import LocaleDropdown from '~/modules/i18n/components/LocaleDropdown.vue';
import { mapGetters } from 'vuex';
import usersettings from './settings/usersettings.vue';
import version from './appVersion.vue';

export default {
    middleware: 'auth',
    components: {
        LocaleDropdown,
        usersettings,
        version,
    },
    data: () => ({
        appName: window.timesheetapp.appName,
        drawer:false,
        isMobile:false,
        mobileBreakpoint:760,
    }),

    beforeDestroy () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, { passive: true })
        }
    },
    mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        logout : async function() {
            // Log out the user.
            await this.$store.dispatch('auth/logout')
            await this.$store.dispatch('time/resetState');


            // Redirect to login.
            this.$router.push({ name: 'login' })
        },
        onResize () {
            if(window.innerWidth < this.mobileBreakpoint) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
                this.drawer = true;
            }
        },
        isCurrentRoute(route) {
            if( (this.$route.name==='time.project' && route.name==='time.customer') 
                || (this.$route.name==='time.customeranalysis' && route.name==='time.customer') ) {
                let project = this.$helper.getProject(this.$route.params.id, this.projects);
                if(project && project.customer.id===route.params.id) {
                    return true;
                }
            }

            if(this.$route.name!==route.name) {
                return false;
            }
            if(route.params && !this.$route.params) {
                return false;
            }
            if(route.params && this.$route.params) {
                if(route.params.id!==this.$route.params.id) {
                    return false;
                }
            }
            return true;
        },
        performLink(route) {
            this.$emit('route-changed',true);
            this.$router.push(route).catch(err => {});//redirect to createsheet
        },
        reloadBaseData() {
            this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
        },
        doAppReload() {
            location.reload();
        }
    },
    computed: {
        ...mapGetters({
            customers: 'time/customers',
            projects: 'time/projects',
            user: 'auth/user',
            isOnline: 'online'
        }),
        customersOrderedByName() {
            if(this.customers) {
                let resultArray = this.$helper.getArrayFromObject(this.customers);
                if(resultArray) {
                    return resultArray.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    });
                }
            }
        },
    }
}
</script>