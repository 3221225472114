<template>
    <form @submit.prevent="submit" id="projectForm">
        <v-text-field v-model="form.name" name="name" ref="firstInput" :label="$t('time_project_title')" ></v-text-field>
        <v-alert v-model="validation.name" color="error" dark dismissible>{{$t('time_alert_choose_a_title')}}</v-alert>

        <v-card>
            <v-list nav dense>
                <v-subheader>{{$t('time_choose_a_customer')}}</v-subheader>
                <v-list-item-group v-model="form.customer" color="primary">
                    <v-list-item v-for="c in customersOrderedByName" :key="c.id" :value="c">
                        {{c.name}}
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>

        <v-alert v-model="validation.customer" color="error" dark dismissible>{{$t('time_alert_choose_a_customer')}}</v-alert>
        
        <v-textarea id="projectFormNotice" v-model="form.notice" name="notice" :label="$t('time_log_notice')"></v-textarea>

        <section class="text-right">
            <template v-if="form.id">
                <v-btn color="secondary" @click="doDelete"><v-icon dark>mdi-delete</v-icon><!--{{$t('time_delete_project')}}--></v-btn>
                <v-btn color="primary" type="submit">{{$t('time_confirm')}}</v-btn>
            </template>
            <template v-else>
                <v-btn color="primary" type="submit">{{$t('time_create_new_project')}}</v-btn>
            </template>
        </section>
    </form>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    components: { },
    props: [
        'data'
    ],
    data: () => ({
        form: {
            id:null,
            name:"",
            notice:"",
            customer:null
        },
        validation:{
            name:false,
            customer:false
        }
    }),
    methods: {
        loadProject:function() {
            if(this.data.id) this.form.id = parseInt(this.data.id);
            if(this.data.name) this.form.name = this.data.name;
            if(this.data.notice) this.form.notice = this.data.notice;

            var that = this;
            if(that.data.customer!==undefined && that.data.customer!==null && that.customers) {
               this.form.customer = this.$helper.getCustomer(that.data.customer.id, that.customers);
            }
        },
        isFormValid:function() {
            var valid = true;
            this.validation.customer = false;
            this.validation.name = false;
            if(!this.form.customer) {
                this.validation.customer = true;
                valid = false;
            }
            if(!this.form.name || this.form.name.lenght<1) {
                this.validation.name = true;
                valid = false;
            }
            return valid;
        },
        submit: async function() {
            let targetUrl = './wp-json/tm_time/v1/project';
            let editMode = false;
            if(!this.isFormValid()) {
                return;
            }
            
            const requestData = {
                title: this.form.name,
                status: "publish",
                customer : this.form.customer.id,
                notice: this.form.notice
            };
            if(this.form.id!==null) {
                editMode = true;
                targetUrl+= '/'+this.form.id;
                requestData.id=this.form.id;
            }
            const { data } = await axios.post(targetUrl, requestData);
            this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
            if(data.id) {
                if(editMode) {
                    this.$router.push({ name: 'time.project', params:{ id : data.id } });//redirect to entry
                } else {
                    this.$router.push({ name: 'time.project', params:{ id : data.id } });
                    //this.$router.push({ name: 'time.projects' });//redirect to overview list
                }
            }
        },
        doDelete : async function() {
            let that = this;
            if(this.data.id && confirm( this.$t('time_project_confirm_delete') )) {
                await axios.delete('./wp-json/tm_time/v1/project/'+this.$route.params.id, { 
                        params: {
                            id:that.$route.params.id, 
                            force: true 
                        }
                    }).then(response => {
                        // Redirect 
                        that.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
                        that.$router.push({ name: 'time.customer', params: { id: that.data.customer.id }});
                    }).catch(error => {
                        if(error.response.status===400) {
                            alert( that.$t('time_delete_project__'+error.response.data.code) );
                        }
                    });

            }
        },
    },
    created() {
        this.loadProject();
        this.$nextTick(() => this.$refs.firstInput.focus());//prefocus on the first Input (defined with ref)
    },
    computed: { 
        ...mapGetters({
            customers: 'time/customers'
        }),
        customersOrderedByName() {
            if(this.customers) {
                let resultArray = this.$helper.getArrayFromObject(this.customers);
                if(resultArray) {
                    return resultArray.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    });
                }
            }
        },
        selectedCustomer:function() {
            if(this.form.customer===null) {
                return this.$t('time_please_choose');
            } else {
                return this.form.customer.name;
            }
        },
    }
}
</script>