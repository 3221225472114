<template>
    <section class="datetimeEditor">
        <div v-if="value">
            <datetime-picker :value="value" @input="applyValueFromDatetimePicker">
                <template v-slot:activator="{ on }">
                    <v-btn block v-on="on" depressed class="dateTimeBtn">
                        <span class="dateValue">{{ $helper.format(value, 'dd.MM.yyyy' ) }}</span>
                        <span class="timeValue">{{ $helper.format(value, 'HH:mm') }}</span>
                    </v-btn>
                </template>
            </datetime-picker>

            <div class="timeAdjustments mt-1">
                <v-btn small depressed @click="adjustTime(-60*24)">-1T</v-btn>
                <v-btn small depressed @click="adjustTime(-5)">-5m</v-btn>
                <v-btn small depressed @click="adjustTime(-1)">-1m</v-btn>
                <v-btn small depressed @click="applyValueFromDatetimePicker(new Date())">jetzt</v-btn>
                <v-btn small depressed @click="adjustTime(+1)">+1m</v-btn>
                <v-btn small depressed @click="adjustTime(+5)">+5m</v-btn>
                <v-btn small depressed @click="adjustTime(+60*24)">+1T</v-btn>
            </div>
        </div>
    </section>
</template>

<script>

import datetimePicker from './datetimePicker.vue';
export default {
    components: {
        datetimePicker
    },
    props: {
        value: {
            type:Date,
            default: null
        },
        type: {
            type:String,
            default: 'start', //or 'end'
        }
    },
    data: () =>({
        inputDialog: false,
    }),
    mounted() {
        
    },
    methods: {
        adjustTime(minutes) {
            this.$emit('input', new Date( this.value.getTime() + ( minutes * 60 * 1000) ));
        },
        applyValueFromDatetimePicker(value) {
            value.setSeconds(0,0);
            this.$emit('input', new Date( value.getTime() ) );
        }
    }
}
</script>