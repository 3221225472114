<template>
    <v-card>
        <v-card-title>
            {{$t('time_create_new_customer')}}
        </v-card-title>
        <v-card-text>
            <customerform :data="customer"></customerform>
        </v-card-text>
    </v-card>
</template>

<script>
import customerform from '../components/customerForm.vue';

export default {
    components: {
        customerform : customerform
    },
    data: () => ({
        customer:{
            title: "",
            notice: ""
        }
    }),
    created: function () {},
    computed:{},
    methods: {},
}
</script>