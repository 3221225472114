<template>
    <v-card>
        <v-card-title>
            {{$t('time_customer_edit_title')}}
        </v-card-title>
        <v-card-text>
            <customerform v-if="customer" :data="customer"></customerform>
            <div class="text-right">
                <v-btn text @click="$router.go(-1)">{{$t('time_back')}}</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios';
import customerform from '../components/customerForm.vue';

export default {
    components: {
        customerform : customerform
    },
    data: () => ({
        customer:null
    }),
    created: function () {
        this.getData();
    },
    computed:{},
    methods: {
        getData : async function() {
            const {data} = await axios.get('./wp-json/tm_time/v1/customer/'+this.$route.params.id);
            this.customer = this.$helper.transformCustomer(data);
        },
    },
}
</script>