<template>
    <v-card class="singleProject">
        <v-card-text class="card-header" v-if="project">
            <h1 class="title display-1 d-flex">
                <v-icon large>mdi-layers</v-icon>
                <span class="ml-1">{{ project.name }}</span>
                <v-spacer></v-spacer>
                <v-btn icon depressed @click="doEdit" :aria-label="$t('time_edit_project')" :title="$t('time_edit_project')">
                    <v-icon dark small>mdi-pencil</v-icon>
                </v-btn>
            </h1>
            <div class="subtitle ml-5">
                <v-icon small>mdi-account</v-icon>
                <router-link v-if="project.customer" :to="{ name: 'time.customer', params: { id: project.customer.id }}">{{project.customer.name}}</router-link>
            </div>
        </v-card-text>

        <v-card-text v-if="project">
            <p v-if="project.notice" class="notice" v-html="project.notice"></p>
            
            <v-container fluid>
                <v-row>
                    <v-btn @click="$router.push({ name: 'time.projectanalysis', params: { id: project.id } })" class="success my-1">
                        <v-icon dark small class="mr-1">mdi-chart-bar-stacked</v-icon>{{$t('time_project_analysis')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <div class="vuetifyBtnGroup createNewSheetBtn">
                        <v-btn dark class="yellow darken-2 dropdownBtn my-1" type="button" @click="createNewSheet">{{ $t('time_start_sheet') }}</v-btn>
                        <v-menu offset-y dark>
                            <template v-slot:activator="{ on }">
                                <v-btn type="button" dark class="yellow darken-2 dropdownToggle px-2 mr-1 my-1" v-on="on"><v-icon>mdi-chevron-down</v-icon></v-btn>
                            </template>
                            <lastSheetTitlesList :sheets="sheets" @useTitle="createNewSheet"></lastSheetTitlesList>
                        </v-menu>
                    </div>
                    <v-btn class="multiEdit my-1" @click="multiEdit.show = !multiEdit.show"><v-icon>mdi-checkbox-multiple-marked-outline</v-icon></v-btn>
                </v-row>
            </v-container>

            <v-card color="grey darken-2 multiEditTool" dark v-if="multiEdit.show">
                <v-card-text>
                    {{$t('time_multi_edit_selected_entries')}} <strong>{{selectedSheets.length}}</strong> 
                    <!--
                    <v-btn small @click="multiSelectAll()">alle selektieren</v-btn>
                    <v-btn small @click="multiSelectNone()">keine</v-btn>
                    -->
                </v-card-text>
                <v-card-actions class="align-end">
                    <v-btn color="secondary" :disabled="selectedSheets.length===0" @click="multiEdit.deleteConfirmDialog = true">{{$t('time_multi_edit_delete_dialog_headline')}}</v-btn>
                    
                    <template>
                        <v-dialog v-model="multiEdit.deleteConfirmDialog" persistent max-width="320">
                            <v-card>
                                <v-card-title class="text-center">{{$t('time_multi_edit_confirm_delete')}}</v-card-title>
                                <!--<v-card-text>text</v-card-text>-->
                                <v-card-actions>
                                    <v-btn depressed @click="multiConfirmDelete(true)">{{$t('ok')}}</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn depressed @click="multiConfirmDelete(false)">{{$t('cancel')}}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>

                    <div class="pl-2">
                        <div>{{$t('time_multi_edit_move_to_project')}}</div>
                        <selectProject v-if="projectForMoveSheets" :project="projectForMoveSheets" @after="multiMoveToProject"></selectProject>
                        <v-btn color="secondary" disabled v-else>{{$t('time_multi_edit_choose_entrie_first')}}</v-btn>
                    </div>
                </v-card-actions>

            </v-card>

            <p class="text-center" v-if="sheets===null">
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </p>
            <sheetlist v-if="sheets!==null" :sheets="sheets" :multi-edit="multiEdit.show" @change="multiEditChange" @reload="reload"></sheetlist>
        </v-card-text>
    </v-card>
        
</template>

<script>
import axios from 'axios'
import sheetlist from './../components/sheetlist.vue';
import sheetlistMultiEditMixin from './../components/sheetlist-multi-edit.mixin.js';
import selectProject from './../components/selectProject.vue';
import lastSheetTitlesList from './../components/lastSheetTitlesList.vue';

export default {
    scrollToTop: false,
    mixins:[sheetlistMultiEditMixin],
    name: 'project',
    components: { sheetlist, selectProject, lastSheetTitlesList },
    data: () => ({
        sheets : null
    }),
    created: function () {
        this.getSheetData();
    },
    computed:{
        project() {
            return this.$store.getters['time/getProjectById']( this.$route.params.id );
        },
    },
    methods: {
        reload() {
            this.getSheetData()
        },
        async getSheetData() {
            await this.$store.dispatch('time/fetchProjectSheets', { id:this.$route.params.id, force:true } );// Fetch the customers
            this.sheets = this.$store.getters['time/getProjectSheets'](this.$route.params.id);
            this.multiSelectNone();//add parameter selected=false to every sheet
        },
        doEdit() {// Redirect 
            this.$router.push({ name: 'time.editproject', params: { id:this.$route.params.id } });
        },
        /**
         * Uses the current project to create a new time-sheet
         * @param {String} sheetTitle (optional) the name for the new sheet
         */
        async createNewSheet(sheetTitle) {
            if(typeof sheetTitle !== 'string') {
                sheetTitle = this.$t('time_new_timelog_default_title');
            }
            let sheet = {
                id:null,
                start:new Date(),
                end:null,
                title:sheetTitle,
                notice:"",
                project:this.project
            };
            let targetUrl = './wp-json/tm_time/v1/sheet';
            let editMode = false;
            
            const requestData = this.$helper.transformSheetFormToSheet(sheet);
            const { data } = await axios.post(targetUrl, requestData);
            this.getSheetData();
        },
        multiEditChange(items) {
            this.sheets = items.concat([]); // concat needed to get a new array
        },
    }
}
</script>