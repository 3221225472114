import axios from 'axios'
import Cookies from 'js-cookie'

/** mutationTypes ************************************************************************/
export const mutationTypes = {
    LOGOUT: 'LOGOUT',
    SAVE_TOKEN: 'SAVE_TOKEN',
    FETCH_USER: 'FETCH_USER',
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
    UPDATE_USER: 'UPDATE_USER',
};

/** store module **************************************************************************/
export const storeModule = {
    namespaced: true,
    state: {
        user: null,
        token: Cookies.get('token')
    },
    getters: {
        user: state => state.user,
        token: state => state.token,
        check: state => state.user !== null
    },
    mutations: {
        [mutationTypes.SAVE_TOKEN](state, { token, remember }) {
            state.token = token
            Cookies.set('token', token, { expires: remember ? 365 : null })
        },
        [mutationTypes.FETCH_USER_SUCCESS](state, { user }) {
            state.user = user
        },
        [mutationTypes.FETCH_USER_FAILURE](state) {
            state.token = null
            Cookies.remove('token')
        },
        [mutationTypes.LOGOUT](state) {
            state.user = null
            state.token = null
            Cookies.remove('token')
        },
        [mutationTypes.UPDATE_USER](state, { user }) {
            state.user = user
        }
    },
    actions: {
        saveToken({ commit, dispatch }, payload) {
            commit(mutationTypes.SAVE_TOKEN, payload)
        },
        async fetchUser({ commit }) {
            try {
                const { data } = await axios.get('./wp-json/tm_time/v1/user')

                commit(mutationTypes.FETCH_USER_SUCCESS, { user: data })
            } catch (e) {
                commit(mutationTypes.FETCH_USER_FAILURE)
            }
        },
        updateUser({ commit }, payload) {
            commit(mutationTypes.UPDATE_USER, payload)
        },
        async logout({ commit }) {
            try {
                //await axios.post('./api/logout')
            } catch (e) { }

            commit(mutationTypes.LOGOUT)
        },
    }
}
