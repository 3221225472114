import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '~/store';//import global store

Vue.use(VueI18n)

// Ready translated locale messages
const messages = {
    en: require('~/lang/en.js').default,
    de: require('~/lang/de.js').default,
};

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: store.getters['lang/locale'], // set locale
    messages, // set locale messages
});

export default i18n;

/**
 * Set the locale for correct display of the internationalization strings
 * @param {String} locale 
 */
export function setI18nLocale(locale) {
    i18n.locale = locale;
    store.dispatch('lang/setLocale', { locale });
}