<template>
    <v-list subheader>
        <v-subheader>Erweiterte Einstellungen</v-subheader>

        <v-list-item-title>Standard Titel festlegen</v-list-item-title>
        <v-combobox hide-selected deletable-chips multiple persistent-hint small-chips dense outlined
            v-model="model"
            @change="saveData"
            >
            <template v-slot:no-data>
                <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        Text eingeben und <kbd>Enter</kbd> drücken um diesen Text anzulegen
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </template>
        </v-combobox>
        
    </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
export default {
    data: () => ({
        model: [],
        items: []
    }),
    mounted() {
        console.log('FIX HERE');
        this.model = this.$store.getters['time/defaultSheetTitles'];
        if(this.model.length === undefined) {
            this.model = [];
        }
    },
    methods:{
        async saveData() {
            let targetUrl = './wp-json/tm_time/v1/basedata/defaultsheettitles';
            const { data } = await axios.post(targetUrl, { defaultsheettitles: this.model });
            if(data.result && data.result==='OK') {
                this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
            }
        }
    }
}
</script>