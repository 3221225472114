<template>
    <div class="dashboard">
        <h3>Dashboard</h3>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <currently-running></currently-running>
                <week-overview></week-overview>
            </div>
            <div class="col-md-6 col-sm-12">
                <latest-sheets></latest-sheets>
            </div>
        </div>
    </div>
</template>

<script>
import latestSheets from './../components/dashboard/latestSheets.vue';
import currentlyRunning from './../components/dashboard/currentlyRunning.vue';
import weekOverview from './../components/dashboard/weekOverview.vue';
export default {
    components: {
        latestSheets,
        currentlyRunning,
        weekOverview,
    },
    mounted() {
        this.$store.dispatch('time/fetchBaseData', { force:true } );// Fetch the customers
        this.$store.dispatch('time/fetchLatestSheets');// Fetch the customers
    }
}
</script>