import axios from 'axios'
import store from '~/store'
import router from '~/router'
import i18n from '~/modules/i18n'

// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()
  //request.headers.common['X-WP-Nonce'] = window.timesheetapp.nonce;

  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response

  if (status >= 500) {
    document.time.openAlert({ title: i18n.t('error_alert_title'), message: i18n.t('error_alert_text') }).then(() => {
      //nothing
    });
  }

  if (status === 401 && store.getters['auth/check']) {
    document.time.openAlert({ title: i18n.t('token_expired_alert_title'), message: i18n.t('token_expired_alert_text') }).then(() => {
      doLogout();
    });
  }

  return Promise.reject(error)
});

async function doLogout() {
  await store.dispatch('auth/logout')
  router.push({ name: 'login' })
}

