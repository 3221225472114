<template>
    <v-card>
        <v-card-title>
            {{$t('time_edit_sheet')}}
        </v-card-title>
        <v-card-text>
            <template v-if="sheet">
                <sheetform :data="sheet"></sheetform>
                <div class="text-right">
                    <button type="button" class="btn btn-text" @click="$router.go(-1)">{{$t('time_back')}}</button>
                </div>
            </template>
            <p class="text-center" v-else>
                <v-progress-circular indeterminate color="primary" ></v-progress-circular>
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';
import sheetform from './../components/sheetForm.vue';

export default {
    name: 'sheet',
    components: {
        sheetform
    },
    props: {
        stopSheet: { //stored by the route
            type:Boolean,
            default:false
        },
        id: { //stored by the route
            type:[String, Number],
            required:true
        }
    },
    data: () => ({
        sheet:null,
    }),
    created: function () {
        this.getData();
    },
    computed:{
        ...mapGetters({
            projects: 'time/projects'
        }),
    },
    methods: {
        getData : async function() {
            const {data} = await axios.get('./wp-json/tm_time/v1/sheet/'+this.id);
            this.sheet = this.$helper.transformSheet(data);
            if(this.sheet.end===null && this.stopSheet===true) {
                this.sheet.end = new Date();
                this.sheet.stoppedNow = true;
            } 
        },
    },
}
</script>